import React from 'react';
import { FormControl } from 'baseui/form-control';
import { Select } from 'baseui/select';

const SelectField = ({ field, form: { errors, setFieldValue, submitCount }, label, caption, ...props }) => {
  const hasError = submitCount > 0 && errors[field.name];
  const handleChange = ({ value }) => {
    setFieldValue(field.name, value);
  };

  return (
    <FormControl label={label} caption={caption} error={hasError && errors[field.name]}>
      <Select {...field} {...props} value={field.value} onChange={handleChange} error={hasError} onBlur={() => {}} />
    </FormControl>
  );
};

export default SelectField;
