import { createTheme, lightThemePrimitives } from 'baseui';
import darkThemeColors from 'baseui/themes/dark-theme-colors';
import { primitives as darkThemePrimitives } from 'baseui/themes/dark-theme-primitives';

export const DEFAULT_THEME = 'light';

const primitives = {
  primaryFontFamily: 'Lato, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
};

const overrides = {
  borders: {
    buttonBorderRadius: '6px',
    inputBorderRadius: '6px',
    popoverBorderRadius: '6px',
    surfaceBorderRadius: '6px',
  },
  sidenavWidth: '250px',
};

const themes = {
  light: createTheme(
    {
      ...lightThemePrimitives,
      ...primitives,
    },
    {
      ...overrides,
    }
  ),
  dark: createTheme(
    {
      ...darkThemePrimitives,
      ...primitives,
    },
    {
      name: 'dark-theme',
      ...darkThemeColors,
      ...overrides,
    }
  ),
};

export default themes;
