import React, { useState } from 'react';
import { Button, KIND } from 'baseui/button';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';
import { StyledFlag } from 'baseui/phone-input/flag';
import { useIntl } from 'react-intl';

import localeIds from './LocaleButton.locale';
import useLocale from './useLocale';

const LocaleButton = props => {
  const [locale, setLocale] = useLocale();
  const { formatMessage } = useIntl();
  const items = [
    {
      id: 'pt-BR',
      label: <StyledFlag iso={formatMessage({ id: localeIds.PORTUGUESE })} />,
    },
    {
      id: 'en-US',
      label: <StyledFlag iso={formatMessage({ id: localeIds.ENGLISH })} />,
    },
  ];
  const [value, setValue] = useState(items.find(({ id }) => id === locale));
  return (
    <StatefulPopover
      placement={PLACEMENT.bottomLeft}
      content={({ close }) => {
        return (
          <StatefulMenu
            items={items}
            onItemSelect={({ item }) => {
              setLocale(item.id);
              setValue(item);
              close();
            }}
          />
        );
      }}
    >
      <Button kind={KIND.minimal} {...props}>
        <span style={{ marginTop: 4 }}>{value.label}</span>
      </Button>
    </StatefulPopover>
  );
};

export default LocaleButton;
