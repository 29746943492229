import React, { forwardRef } from 'react';
import { gql } from 'apollo-boost';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { getOr } from 'lodash/fp';
import { useIntl } from 'react-intl';

import localeIds from './EadModulesForm.locale';
import { deleteCacheByKey } from '../apolloClient';
import InputField from '../common/InputField';
import SelectField from '../common/SelectField';
import NotificationError from '../common/NotificationError';
import FormFooterButtons from '../common/FormFooterButtons';
import { GET_MODULES_FIELD_OPTIONS } from './EadCoursesForm';

const VIDEO_OPTION = gql`
  fragment VideoOption on CourseVideo {
    _id
    title
  }
`;

const MODULE_FIELDS = gql`
  fragment ModuleFields on CourseModule {
    _id
    description
    minScore
    videos {
      ...VideoOption
    }
  }
  ${VIDEO_OPTION}
`;

const CREATE_MODULE = gql`
  mutation CreateModule($record: CreateOneCourseModuleInput!) {
    courseModule: createCourseModule(record: $record) {
      record {
        ...ModuleFields
      }
    }
  }
  ${MODULE_FIELDS}
`;

const UPDATE_MODULE = gql`
  mutation UpdateModule($record: UpdateByIdCourseModuleInput!) {
    courseModule: updateCourseModule(record: $record) {
      record {
        ...ModuleFields
      }
    }
  }
  ${MODULE_FIELDS}
`;

export const GET_VIDEOS_FIELD_OPTIONS = gql`
  query GetVideosFieldOptions {
    courseVideoMany {
      ...VideoOption
    }
  }
  ${VIDEO_OPTION}
`;

const EadModulesForm = forwardRef(({ courseModule, onSuccess, modal }, ref) => {
  const [createModule, { error: createError }] = useMutation(CREATE_MODULE, {
    refetchQueries: ['GetModules', { query: GET_MODULES_FIELD_OPTIONS }],
    update: store => {
      deleteCacheByKey(store, 'courseModulePagination');
    },
  });
  const [updateModule, { error: updateError }] = useMutation(UPDATE_MODULE);
  const { data: videoOptionsData, loading: loadingVideoOptions } = useQuery(GET_VIDEOS_FIELD_OPTIONS);
  const { formatMessage } = useIntl();
  const isEditing = Boolean(courseModule);
  const videoOptions = videoOptionsData ? videoOptionsData.courseVideoMany : [];
  const initialValues = {
    description: '',
    minScore: '',
    videos: [],
    ...(isEditing && {
      _id: courseModule._id,
      description: courseModule.description || '',
      minScore: typeof courseModule.minScore === 'number' ? courseModule.minScore : '',
      videos: courseModule.videos || [],
    }),
  };
  const validationSchema = Yup.object().shape({
    description: Yup.string().required(),
    minScore: Yup.number()
      .min(0)
      .max(100)
      .required(),
  });
  const handleSubmit = async ({ description, minScore, videos }, { setSubmitting }) => {
    try {
      const variables = {
        record: {
          description,
          minScore: Number(minScore),
          videos: videos.map(({ _id }) => _id),
          ...(isEditing && { _id: courseModule._id }),
        },
      };
      const request = isEditing ? updateModule : createModule;
      const { data } = await request({ variables });
      onSuccess(data.courseModule.record);
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };
  const error = getOr(false, 'networkError.result', createError) || getOr(false, 'networkError.result', updateError);

  return (
    <>
      {error && <NotificationError error={error}>Erro</NotificationError>}
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} ref={ref}>
        {({ handleSubmit, handleReset, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            {isEditing && (
              <Field name="_id" label={formatMessage({ id: localeIds.FIELD_ID })} component={InputField} disabled />
            )}
            <Field
              label={formatMessage({ id: localeIds.FIELD_DESCRIPTION })}
              name="description"
              component={InputField}
              autoComplete="off"
            />
            <Field
              label={formatMessage({ id: localeIds.FIELD_MIN_SCORE })}
              name="minScore"
              component={InputField}
              type="number"
              endEnhancer="%"
              autoComplete="off"
              overrides={{ Input: { props: { min: 0, max: 100 } } }}
            />
            <Field
              label={formatMessage({ id: localeIds.FIELD_VIDEOS })}
              name="videos"
              component={SelectField}
              isLoading={loadingVideoOptions}
              options={videoOptions}
              labelKey="title"
              valueKey="_id"
              multi
              autoComplete="off"
            />
            <FormFooterButtons
              onClear={handleReset}
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
              clearable={!isEditing}
              modal={modal}
            />
          </form>
        )}
      </Formik>
    </>
  );
});

export default EadModulesForm;
