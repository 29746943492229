import React, { useState } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import { useIntl } from 'react-intl';

import localeIds from './EadCoursesTable.locale';
import { useEad } from './EadContext';
import Table, { TableAction } from '../common/Table';

const COURSE_ROW = gql`
  fragment CourseRow on Course {
    _id
    name
    description
    minScore
    modules {
      _id
      description
    }
  }
`;

const GET_COURSES = gql`
  query GetCourses($page: Int, $perPage: Int, $filter: FilterFindManyCourseInput, $sort: SortFindManyCourseInput) {
    coursePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
      count
      items {
        ...CourseRow
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
  ${COURSE_ROW}
`;

const EadCoursesTable = ({ onEdit, onDelete, onQuickPreview }) => {
  const {
    courses: {
      pagination: { page, perPage },
      setPagination,
    },
  } = useEad();
  const { formatMessage } = useIntl();
  const [nextPage, setNextPage] = useState(page);
  const { data, loading } = useQuery(GET_COURSES, {
    variables: { page: nextPage, perPage },
    onCompleted: () => setPagination({ page: nextPage, perPage }),
  });
  const pageCount = getOr(1, 'coursePagination.pageInfo.pageCount', data);
  const items = getOr([], 'coursePagination.items', data);
  const columns = [
    {
      key: 'name',
      title: formatMessage({ id: localeIds.COLUMN_NAME }),
      render: ({ name }) => name,
    },
    {
      key: 'description',
      title: formatMessage({ id: localeIds.COLUMN_DESCRIPTION }),
      render: ({ description }) => description,
    },
    {
      key: 'minScore',
      title: formatMessage({ id: localeIds.COLUMN_MIN_SCORE }),
      render: ({ minScore }) => `${minScore}%`,
    },
    {
      key: 'actions',
      title: formatMessage({ id: localeIds.COLUMN_ACTIONS }),
      render: item => (
        <>
          <TableAction onClick={() => onQuickPreview(item)}>
            <FontAwesomeIcon icon={faEye} />
          </TableAction>
          <TableAction onClick={() => onEdit(item)}>
            <FontAwesomeIcon icon={faEdit} />
          </TableAction>
          <TableAction>
            <FontAwesomeIcon icon={faTrashAlt} onClick={() => onDelete(item)} />
          </TableAction>
        </>
      ),
    },
  ];
  const isPageDeleted = page > 1 && page > pageCount;
  if (isPageDeleted) {
    setNextPage(page - 1);
  }

  return (
    <Table
      data-testid="ead-courses-table"
      columns={columns}
      items={items}
      itemKey="_id"
      isLoading={loading}
      page={page}
      pageCount={pageCount}
      onPageChange={setNextPage}
    />
  );
};

export default EadCoursesTable;
