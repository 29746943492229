const namespace = 'pages.EadPromotersPage';

const keys = {
  TITLE: `${namespace}.title`,
};

const en_US = {
  [keys.TITLE]: 'Promoters',
};

const pt_BR = {
  [keys.TITLE]: 'Repositores',
};

export { en_US, pt_BR };

export default keys;
