import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as LogoBlack } from '../images/anthorLogoBlack.svg';
import { ReactComponent as LogoWhite } from '../images/anthorLogoWhite.svg';

const AnthorLogo = ({ type, ...props }) => {
  const style = { width: 150 };

  if (type === 'white') {
    return <LogoWhite style={style} {...props} />;
  }

  return <LogoBlack style={style} {...props} />;
};

AnthorLogo.propTypes = {
  type: PropTypes.oneOf(['black', 'white']),
};

AnthorLogo.defaultProps = {
  type: 'black',
};

export default AnthorLogo;
