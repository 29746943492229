const namespace = 'components.EstablishmentSelect';

const keys = {
  PLACEHOLDER: `${namespace}.placeholder`,
};

const en_US = {
  [keys.PLACEHOLDER]: 'Pick a establishment',
};

const pt_BR = {
  [keys.PLACEHOLDER]: 'Selecione um estabelecimento',
};

export { en_US, pt_BR };

export default keys;
