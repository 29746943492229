export const COMPANY = {
  STATUS: {
    APPROVED: 'approved',
    PENDING: 'pending',
    CANCELED: 'canceled',
  },
  TYPE: {
    CHAIN: 'chain',
    INDUSTRY: 'industry',
  },
};
