import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import useRouter from './useRouter';
import GuestRoute from './GuestRoute';
import LoginPage from './LoginPage';

const GuestApp = () => {
  const { location } = useRouter();

  return (
    <div data-testid="guest-app">
      <Switch>
        <GuestRoute component={LoginPage} path="/login" />
        <Redirect to={{ pathname: '/login', state: { from: location } }} />
      </Switch>
    </div>
  );
};

export default GuestApp;
