const namespace = 'common.FormFooterButtons';

const keys = {
  BUTTON_CLEAR: `${namespace}.button.clear`,
  BUTTON_SAVE: `${namespace}.button.save`,
};

const en_US = {
  [keys.BUTTON_CLEAR]: 'Clear',
  [keys.BUTTON_SAVE]: 'Save',
};

const pt_BR = {
  [keys.BUTTON_CLEAR]: 'Limpar',
  [keys.BUTTON_SAVE]: 'Salvar',
};

export { en_US, pt_BR };

export default keys;
