import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { Button, KIND } from 'baseui/button';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';
import { useIntl } from 'react-intl';
import { Avatar } from 'baseui/avatar';

import localeIds from './UserButton.locale';
import useUser from './useUser';

const UserButton = () => {
  const [user] = useUser();
  const { formatMessage } = useIntl();
  const client = useApolloClient();

  return (
    <StatefulPopover
      placement={PLACEMENT.bottomLeft}
      content={({ close }) => {
        return (
          <StatefulMenu
            items={[
              {
                label: formatMessage({ id: localeIds.LOGOUT }),
                onClick: async () => client.resetStore(),
              },
            ]}
            onItemSelect={({ item }) => {
              close();
              item.onClick();
            }}
            overrides={{
              Option: {
                style: { minWidth: '100px' },
              },
            }}
          />
        );
      }}
    >
      <Button kind={KIND.minimal}>
        <Avatar name={user.name.split(' ')[0]} size="scale900" />
        <span style={{ color: 'white', marginLeft: 8 }}>{user.name}</span>
      </Button>
    </StatefulPopover>
  );
};

export default UserButton;
