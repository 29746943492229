const namespace = 'components.ActivitiesTable';

const keys = {
  COLUMN_DATE: `${namespace}.column.date`,
  COLUMN_COMPANY: `${namespace}.column.company`,
  COLUMN_ESTABLISHMENT: `${namespace}.column.establishment`,
  COLUMN_PRICE: `${namespace}.column.price`,
  COLUMN_STATUS: `${namespace}.column.status`,
};

const en_US = {
  [keys.COLUMN_DATE]: 'Date',
  [keys.COLUMN_COMPANY]: 'Company',
  [keys.COLUMN_ESTABLISHMENT]: 'Establishment',
  [keys.COLUMN_PRICE]: 'Price',
  [keys.COLUMN_STATUS]: 'Status',
};

const pt_BR = {
  [keys.COLUMN_DATE]: 'Data',
  [keys.COLUMN_COMPANY]: 'Empresa',
  [keys.COLUMN_ESTABLISHMENT]: 'Estabelecimento',
  [keys.COLUMN_PRICE]: 'Preço',
  [keys.COLUMN_STATUS]: 'Status',
};

export { en_US, pt_BR };

export default keys;
