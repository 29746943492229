import React, { createContext, useContext, useState } from 'react';

const DEFAULT_PER_PAGE = 10;
const DEFAULT_PAGE = 1;

const IndustryContext = createContext();

const IndustryProvider = ({ children }) => {
  const [pagination, setPagination] = useState({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE });
  const [companyFilter, setCompanyFilter] = useState(null);

  return (
    <IndustryContext.Provider
      value={{
        pagination,
        setPagination,
        companyFilter,
        setCompanyFilter,
      }}
    >
      {children}
    </IndustryContext.Provider>
  );
};

const useIndustry = () => {
  const context = useContext(IndustryContext);
  return context;
};

export { IndustryProvider, useIndustry };
