const namespace = 'ead.EadVideosPage';

const keys = {
  TITLE: `${namespace}.title`,
  CREATE_VIDEO: `${namespace}.createCourse`,
  EDIT_VIDEO: `${namespace}.editCourse`,
  DELETE_VIDEO: `${namespace}.deleteCourse`,
  CANCEL_BUTTON: `${namespace}.cancelButton`,
  DELETE_BUTTON: `${namespace}.deleteButton`,
};

const en_US = {
  [keys.TITLE]: 'Videos',
  [keys.CREATE_VIDEO]: 'Create video',
  [keys.EDIT_VIDEO]: 'Edit video',
  [keys.DELETE_VIDEO]: 'Delete video?',
  [keys.CANCEL_BUTTON]: 'Cancel',
  [keys.DELETE_BUTTON]: 'Delete',
};

const pt_BR = {
  [keys.TITLE]: 'Vídeos',
  [keys.CREATE_VIDEO]: 'Criar vídeo',
  [keys.EDIT_VIDEO]: 'Editar vídeo',
  [keys.DELETE_VIDEO]: 'Deletar vídeo?',
  [keys.CANCEL_BUTTON]: 'Cancelar',
  [keys.DELETE_BUTTON]: 'Deletar',
};

export { en_US, pt_BR };

export default keys;
