import React from 'react';
import { FormControl } from 'baseui/form-control';
import { Checkbox } from 'baseui/checkbox';
import PropTypes from 'prop-types';

const CheckboxField = ({
  field,
  form: { touched, errors, setFieldValue, submitCount },
  label,
  checkboxLabel,
  caption,
  onChange,
  ...props
}) => {
  const hasError = submitCount > 0 && errors[field.name];
  const handleChange = () => setFieldValue(field.name, !field.value);

  return (
    <FormControl label={label} caption={caption} error={hasError && errors[field.name]}>
      <Checkbox {...field} {...props} checked={field.value} onChange={handleChange} error={hasError}>
        {checkboxLabel}
      </Checkbox>
    </FormControl>
  );
};

CheckboxField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  caption: PropTypes.string,
};

export default CheckboxField;
