import React, { createContext, useContext, useState } from 'react';

const DEFAULT_PER_PAGE = 15;
const DEFAULT_PAGE = 1;

const EadContext = createContext();

const EadProvider = ({ children }) => {
  const [coursesPagination, setCoursesPagination] = useState({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE });
  const [modulesPagination, setModulesPagination] = useState({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE });
  const [videosPagination, setVideosPagination] = useState({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE });
  const [questionsPagination, setQuestionsPagination] = useState({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE });
  const [promotersPagination, setPromotersPagination] = useState({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE });

  return (
    <EadContext.Provider
      value={{
        courses: {
          pagination: coursesPagination,
          setPagination: setCoursesPagination,
        },
        modules: {
          pagination: modulesPagination,
          setPagination: setModulesPagination,
        },
        videos: {
          pagination: videosPagination,
          setPagination: setVideosPagination,
        },
        questions: {
          pagination: questionsPagination,
          setPagination: setQuestionsPagination,
        },
        promoters: {
          pagination: promotersPagination,
          setPagination: setPromotersPagination,
        },
      }}
    >
      {children}
    </EadContext.Provider>
  );
};

const useEad = () => useContext(EadContext);

export { EadProvider, useEad };
