const namespace = 'ead.EadCoursesForm';

const keys = {
  FIELD_ID: `${namespace}.field.id`,
  FIELD_NAME: `${namespace}.field.name`,
  FIELD_DESCRIPTION: `${namespace}.field.description`,
  FIELD_MODULES: `${namespace}.field.modules`,
  FIELD_MIN_SCORE: `${namespace}.field.minScore`,
  NOTIFICATION_ERROR: `${namespace}.notification.error`,
  NOTIFICATION_CREATE_SUCCESS: `${namespace}.notification.createSuccess`,
  NOTIFICATION_UPDATE_SUCCESS: `${namespace}.notification.updateSuccess`,
};

const en_US = {
  [keys.FIELD_ID]: 'ID',
  [keys.FIELD_NAME]: 'Name',
  [keys.FIELD_DESCRIPTION]: 'Description',
  [keys.FIELD_MODULES]: 'Modules',
  [keys.FIELD_MIN_SCORE]: 'Minimal Score',
  [keys.NOTIFICATION_ERROR]: 'An error has occurred',
  [keys.NOTIFICATION_CREATE_SUCCESS]: 'Course created',
  [keys.NOTIFICATION_UPDATE_SUCCESS]: 'Course updated',
};

const pt_BR = {
  [keys.FIELD_ID]: 'ID',
  [keys.FIELD_NAME]: 'Nome',
  [keys.FIELD_DESCRIPTION]: 'Descrição',
  [keys.FIELD_MODULES]: 'Módulos',
  [keys.FIELD_MIN_SCORE]: 'Pontuação Mínima',
  [keys.NOTIFICATION_ERROR]: 'Ocorreu um erro',
  [keys.NOTIFICATION_CREATE_SUCCESS]: 'Curso criado',
  [keys.NOTIFICATION_UPDATE_SUCCESS]: 'Curso atualizado',
};

export { en_US, pt_BR };

export default keys;
