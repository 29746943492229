import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalHeader, ModalFooter, ModalButton } from 'baseui/modal';
import { useIntl } from 'react-intl';

import localeIds from './EadQuestionsPage.locale';
import useEadResourcePage from './useEadResourcePage';
import { deleteCacheByKey } from '../apolloClient';
import PageLayout from '../app/PageLayout';
import EadQuestionsTable from './EadQuestionsTable';
import EadQuestionsForm from './EadQuestionsForm';

const DELETE_QUESTION = gql`
  mutation DeleteQuestion($_id: MongoID!) {
    courseQuestionRemoveById(_id: $_id) {
      _id: recordId
    }
  }
`;

const EadQuestionsPage = () => {
  const {
    isFormOpen,
    isConfirmDeleteOpen,
    selectedResource: selectedQuestion,
    openForm,
    closeForm,
    openConfirmDelete,
    closeConfirmDelete,
  } = useEadResourcePage();
  const { formatMessage } = useIntl();
  const [deleteQuestion, { loading: isDeleting }] = useMutation(DELETE_QUESTION, {
    refetchQueries: ['GetQuestions'],
    update: store => {
      deleteCacheByKey(store, 'courseQuestionPagination');
    },
  });

  return (
    <>
      <PageLayout
        title={formatMessage({ id: localeIds.TITLE })}
        actions={<Button onClick={() => openForm()}>{formatMessage({ id: localeIds.CREATE_QUESTION })}</Button>}
      >
        <EadQuestionsTable onEdit={openForm} onDelete={openConfirmDelete} />
      </PageLayout>
      <Modal isOpen={isFormOpen} onClose={closeForm}>
        <ModalHeader>
          {selectedQuestion
            ? formatMessage({ id: localeIds.EDIT_QUESTION })
            : formatMessage({ id: localeIds.CREATE_QUESTION })}
        </ModalHeader>
        <ModalBody>
          <EadQuestionsForm
            question={selectedQuestion}
            onOptionCreated={openForm}
            onOptionUpdated={openForm}
            onCreated={openForm}
            onUpdated={closeForm}
            modal
          />
        </ModalBody>
      </Modal>
      <Modal isOpen={isConfirmDeleteOpen} isLoading={!isDeleting} onClose={closeConfirmDelete}>
        <ModalHeader>{formatMessage({ id: localeIds.DELETE_QUESTION })}</ModalHeader>
        <ModalFooter>
          <ModalButton disabled={isDeleting} onClick={closeConfirmDelete}>
            {formatMessage({ id: localeIds.CANCEL_BUTTON })}
          </ModalButton>
          <ModalButton
            disabled={isDeleting}
            isLoading={isDeleting}
            onClick={async () => {
              try {
                await deleteQuestion({ variables: { _id: selectedQuestion._id } });
                closeConfirmDelete();
              } catch (error) {
                console.error(error);
              }
            }}
          >
            {formatMessage({ id: localeIds.DELETE_BUTTON })}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EadQuestionsPage;
