import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Layout from './Layout';
import PrivateRoute from './PrivateRoute';
import NotFoundPage from './NotFoundPage';
import { EadProvider } from '../ead/EadContext';
import EadModulesPage from '../ead/EadModulesPage';
import EadCoursesPage from '../ead/EadCoursesPage';
import EadQuestionsPage from '../ead/EadQuestionsPage';
import EadVideosPage from '../ead/EadVideosPage';
import EadPromotersPage from '../ead/EadPromotersPage';
import { IndustryProvider } from '../industry/IndustryContext';
import IndustryPage from '../industry/IndustryPage';

const PrivateApp = () => {
  return (
    <div data-testid="private-app">
      <Layout>
        <IndustryProvider>
          <EadProvider>
            <Switch>
              <Route render={() => <Redirect to="/industry" />} path="/" exact />
              <PrivateRoute component={IndustryPage} path="/industry" />
              <PrivateRoute component={EadCoursesPage} path="/ead/courses" />
              <PrivateRoute component={EadModulesPage} path="/ead/modules" />
              <PrivateRoute component={EadQuestionsPage} path="/ead/questions" />
              <PrivateRoute component={EadVideosPage} path="/ead/videos" />
              <PrivateRoute component={EadPromotersPage} path="/ead/promoters" />
              <PrivateRoute component={NotFoundPage} path="*" />
            </Switch>
          </EadProvider>
        </IndustryProvider>
      </Layout>
    </div>
  );
};

export default PrivateApp;
