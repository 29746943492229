import React from 'react';
import {
  HeaderNavigation,
  ALIGN,
  StyledNavigationItem as NavigationItem,
  StyledNavigationList as NavigationList,
} from 'baseui/header-navigation';
import { ThemeProvider } from 'baseui';

import themes from '../themes';
import AnthorLogo from '../common/AnthorLogo';
import LocaleButton from './LocaleButton';
import UserButton from './UserButton';
import ThemeButton from './ThemeButton';

const Navbar = () => {
  return (
    <ThemeProvider theme={themes.dark}>
      <HeaderNavigation>
        <NavigationList $align={ALIGN.left}>
          <NavigationItem style={{ display: 'flex', alignItems: 'center' }}>
            <AnthorLogo type="white" />
          </NavigationItem>
        </NavigationList>
        <NavigationList $align={ALIGN.center} />
        <NavigationList $align={ALIGN.right} style={{ paddingRight: 24 }}>
          <NavigationItem style={{ display: 'flex' }}>
            <LocaleButton style={{ marginRight: '8px' }} />
            <ThemeButton style={{ marginRight: '8px' }} />
            <UserButton />
          </NavigationItem>
        </NavigationList>
      </HeaderNavigation>
    </ThemeProvider>
  );
};

export default Navbar;
