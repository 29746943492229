import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { THEME_STORAGE_KEY } from '../apolloClient';

const GET_THEME = gql`
  {
    theme @client
  }
`;

const useTheme = () => {
  const {
    data: { theme },
    client,
  } = useQuery(GET_THEME);
  const setTheme = theme => {
    client.writeData({ data: { theme } });
    localStorage.setItem(THEME_STORAGE_KEY, theme);
  };

  return [theme, setTheme];
};

export default useTheme;
