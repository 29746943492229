import React, { useState } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import { getOr, isEmpty } from 'lodash/fp';
import { Block } from 'baseui/block';

import { useIndustry } from './IndustryContext';
import Table, { TableAction } from '../common/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import IndustryStatusTag from './IndustryStatusTag';
import CompanySelect from '../common/CompanySelect';

const INDUSTRY_ROW = gql`
  fragment IndustryRow on Company {
    _id
    name
    companyName
    cnpj
    status
    createdAt
  }
`;

const GET_INDUSTRIES = gql`
  query GetIndustries($page: Int, $perPage: Int, $filter: FilterFindManyCompanyInput, $sort: SortFindManyCompanyInput) {
    industries: companyPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
      count
      items {
        ...IndustryRow
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
  ${INDUSTRY_ROW}
`;

const IndustryTable = ({ onVoucherClick }) => {
  const {
    pagination: { page, perPage },
    setPagination,
    companyFilter,
    setCompanyFilter,
  } = useIndustry();
  const { formatDate } = useIntl();
  const [nextPage, setNextPage] = useState(page);
  const { data, loading } = useQuery(GET_INDUSTRIES, {
    variables: {
      page: nextPage,
      perPage,
      ...(!isEmpty(companyFilter) && { filter: { _id: companyFilter[0]._id } }),
    },
    onCompleted: () => setPagination({ page: nextPage, perPage }),
  });
  const pageCount = getOr(1, 'industries.pageInfo.pageCount', data);
  const items = getOr([], 'industries.items', data);
  const columns = [
    {
      key: 'fantasyName',
      title: 'Nome Fantasia',
      render: ({ name }) => name,
    },
    {
      key: 'cnpj',
      title: 'CNPJ',
      render: ({ cnpj }) => cnpj,
    },
    {
      key: 'status',
      title: 'Status',
      render: ({ status }) => <IndustryStatusTag status={status} />,
    },
    {
      key: 'createdAt',
      title: 'Criada em',
      render: ({ createdAt }) => formatDate(createdAt, { day: 'numeric', month: 'numeric', year: 'numeric' }),
    },
    {
      key: 'actions',
      title: 'Ações',
      render: item => (
        <TableAction onClick={() => onVoucherClick(item)}>
          <FontAwesomeIcon icon={faCoins} />
        </TableAction>
      ),
    },
  ];
  const isPageDeleted = page > 1 && page > pageCount;
  if (isPageDeleted) {
    setNextPage(page - 1);
  }

  return (
    <>
      <Block display="flex" justifyContent="flex-end" marginBottom="scale800">
        <Block width="300px">
          <CompanySelect
            value={companyFilter}
            onChange={({ value }) => {
              setCompanyFilter(value);
              setPagination({ page: 1, perPage });
              setNextPage(1);
            }}
          />
        </Block>
      </Block>
      <Table
        data-testid="industry-table"
        columns={columns}
        items={items}
        itemKey="_id"
        isLoading={loading}
        page={page}
        pageCount={pageCount}
        onPageChange={setNextPage}
      />
    </>
  );
};

export default IndustryTable;
