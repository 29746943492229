const namespace = 'pages.ActivitiesPage';

const keys = {
  TITLE: `${namespace}.title`,
};

const en_US = {
  [keys.TITLE]: 'Activities',
};

const pt_BR = {
  [keys.TITLE]: 'Atividades',
};

export { en_US, pt_BR };

export default keys;
