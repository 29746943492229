import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { LOCALE_STORAGE_KEY } from '../apolloClient';

const GET_LOCALE = gql`
  {
    locale @client
  }
`;

const useLocale = () => {
  const {
    data: { locale },
    client,
  } = useQuery(GET_LOCALE);
  const setLocale = locale => {
    client.writeData({ data: { locale } });
    localStorage.setItem(LOCALE_STORAGE_KEY, locale);
  };

  return [locale, setLocale];
};

export default useLocale;
