import React from 'react';
import { useStyletron } from 'baseui';

import Navbar from './Navbar';
import Sidenav from './Sidenav';

const Layout = ({ children }) => {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      })}
    >
      <Navbar />
      <div
        className={css({
          backgroundColor: theme.colors.background,
          display: 'flex',
          flex: '1',
        })}
      >
        <div
          className={css({
            width: theme.sidenavWidth,
            borderRightColor: theme.colors.mono500,
            borderRightStyle: theme.borders.border500.borderStyle,
            borderRightWidth: theme.borders.border500.borderWidth,
            flexShrink: 0,
            flexGrow: 0,
          })}
        >
          <Sidenav />
        </div>
        <div className={css({ flexGrow: 1 })}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
