import React from 'react';
import { styled } from 'baseui';
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
  StyledAction,
  Filter,
} from 'baseui/table';
import { Spinner } from 'baseui/spinner';
import { Pagination } from 'baseui/pagination';
import { Checkbox } from 'baseui/checkbox';
import PropTypes from 'prop-types';

const SpinnerWrapper = styled('div', ({ $theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  paddingTop: $theme.sizing.scale1600,
  boxSizing: 'border-box',
  backgroundColor: 'rgba(0,0,0,.1)',
}));

const PaginationWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: $theme.sizing.scale600,
}));

const CheckboxFilter = ({ children, ...props }) => (
  <Checkbox
    overrides={{
      Root: {
        style: ({ $theme }) => ({
          marginTop: $theme.sizing.scale400,
          marginBottom: $theme.sizing.scale400,
        }),
      },
      Checkmark: { style: { marginLeft: 0 } },
    }}
    {...props}
  >
    {children}
  </Checkbox>
);

const Table = ({ columns, items, itemKey, isLoading, page, pageCount, onPageChange, ...props }) => (
  <>
    <StyledTable style={{ overflow: 'auto' }} {...props}>
      <StyledHead>
        {columns.map(column => (
          <StyledHeadCell key={column.key}>
            {column.title}
            {typeof column.renderFilter === 'function' && column.renderFilter(column)}
          </StyledHeadCell>
        ))}
      </StyledHead>
      <StyledBody style={{ position: 'relative', minHeight: 200 }}>
        {isLoading && (
          <SpinnerWrapper data-testid="table-loader">
            <Spinner />
          </SpinnerWrapper>
        )}
        {items.map(item => (
          <StyledRow key={item[itemKey]}>
            {columns.map(column => (
              <StyledCell key={column.key}>{column.render(item)}</StyledCell>
            ))}
          </StyledRow>
        ))}
      </StyledBody>
    </StyledTable>
    <PaginationWrapper>
      <Pagination
        onPageChange={({ nextPage }) => {
          if (nextPage < 1) {
            return;
          }

          if (nextPage > pageCount) {
            return;
          }
          onPageChange(nextPage);
        }}
        currentPage={page}
        numPages={pageCount}
        overrides={{
          PrevButton: {
            props: {
              disabled: isLoading || page === 1,
            },
          },
          NextButton: {
            props: {
              disabled: isLoading || page === pageCount,
            },
          },
          Select: {
            props: {
              disabled: isLoading,
            },
          },
        }}
      />
    </PaginationWrapper>
  </>
);

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      render: PropTypes.func.isRequired,
      renderFilter: PropTypes.func,
    })
  ),
  items: PropTypes.array,
  itemKey: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  page: PropTypes.number,
  pageCount: PropTypes.number,
  onPageChange: PropTypes.func,
};

Table.defaultProps = {
  columns: [],
  items: [],
  isLoading: false,
  page: 1,
  pageCount: 10,
  onPageChange: () => {},
};

export default Table;

export { StyledAction as TableAction, Filter as TableFilter, CheckboxFilter };
