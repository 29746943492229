import React, { useRef } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalHeader, ModalFooter, ModalButton } from 'baseui/modal';
import { getOr } from 'lodash/fp';
import { useIntl } from 'react-intl';

import localeIds from './EadVideosPage.locale';
import useEadResourcePage from './useEadResourcePage';
import { deleteCacheByKey } from '../apolloClient';
import PageLayout from '../app/PageLayout';
import EadVideosForm from './EadVideosForm';
import EadVideosTable from './EadVideosTable';
import { GET_VIDEOS_FIELD_OPTIONS } from './EadModulesForm';

const DELETE_VIDEO = gql`
  mutation DeleteVideo($_id: MongoID!) {
    courseVideoRemoveById(_id: $_id) {
      _id: recordId
    }
  }
`;

const EadQuestionsPage = () => {
  const {
    isFormOpen,
    isConfirmDeleteOpen,
    selectedResource: selectedVideo,
    openForm,
    closeForm,
    openConfirmDelete,
    closeConfirmDelete,
    openQuickPreview,
  } = useEadResourcePage();
  const [deleteVideo, { loading: isDeleting }] = useMutation(DELETE_VIDEO, {
    refetchQueries: ['GetVideos', { query: GET_VIDEOS_FIELD_OPTIONS }],
    update: store => {
      deleteCacheByKey(store, 'courseVideoPagination');
    },
  });
  const { formatMessage } = useIntl();
  const formRef = useRef(null);
  const isSubmitting = getOr(false, 'current.state.isSubmitting', formRef);
  const handleConfirmClick = async () => {
    try {
      await deleteVideo({ variables: { _id: selectedVideo._id } });
      closeConfirmDelete();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <PageLayout
        title={formatMessage({ id: localeIds.TITLE })}
        actions={<Button onClick={() => openForm()}>{formatMessage({ id: localeIds.CREATE_VIDEO })}</Button>}
      >
        <EadVideosTable onEdit={openForm} onDelete={openConfirmDelete} onQuickPreview={openQuickPreview} />
      </PageLayout>
      <Modal isOpen={isFormOpen} closeable={!isSubmitting} onClose={closeForm}>
        <ModalHeader>
          {selectedVideo ? formatMessage({ id: localeIds.EDIT_VIDEO }) : formatMessage({ id: localeIds.CREATE_VIDEO })}
        </ModalHeader>
        <ModalBody>
          <EadVideosForm ref={formRef} video={selectedVideo} onSuccess={closeForm} modal />
        </ModalBody>
      </Modal>
      <Modal isOpen={isConfirmDeleteOpen} closeable={!isDeleting} onClose={closeConfirmDelete}>
        <ModalHeader>{formatMessage({ id: localeIds.DELETE_VIDEO })}</ModalHeader>
        <ModalFooter>
          <ModalButton disabled={isDeleting} onClick={closeConfirmDelete}>
            {formatMessage({ id: localeIds.CANCEL_BUTTON })}
          </ModalButton>
          <ModalButton disabled={isDeleting} isLoading={isDeleting} onClick={handleConfirmClick}>
            {formatMessage({ id: localeIds.DELETE_VIDEO })}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EadQuestionsPage;
