const baseuiPtBr = {
  accordion: {
    collapse: 'Encolher',
    expand: 'Expandir',
  },
  breadcrumbs: {
    ariaLabel: 'Navegaçaão estrutural',
  },
  datepicker: {
    ariaLabel: 'Selecione uma data',
    previousMonth: 'Mês anterior',
    nextMonth: 'Próximo mês',
    screenReaderMessageInput:
      'Pressione a tecla de seta para baixo para interagir com o calendário e selecione uma data. Pressione o botão de escape para fechar o calendário.',
    quickSelectLabel: 'Escolha um intervalo de datas',
    quickSelectAriaLabel: 'Escolha um intervalo de datas',
    timeSelectLabel: 'Hora de início',
    timePickerAriaLabel: 'Selecione a data',
    timezonePickerAriaLabel: 'Selecione o fuso horário',
  },
  buttongroup: {
    ariaLabel: 'grupo de botões',
  },
  fileuploader: {
    dropFilesToUpload: 'Solte os arquivos aqui para fazer o upload',
    or: 'ou',
    browseFiles: 'Buscar arquivos',
    retry: 'Tentar upload Novamente',
    cancel: 'Cancelar',
  },
  menu: {
    noResultsMsg: 'Sem resultado',
  },
  modal: {
    close: 'Fechar',
  },
  pagination: {
    prev: 'Anterior',
    next: 'Próxima',
    preposition: 'de',
  },
  select: {
    noResultsMsg: 'Nenhum resultado encontrado',
    placeholder: 'Selecione...',
    create: 'Criar',
  },
  toast: {
    close: 'Fechar',
  },
};

export default baseuiPtBr;
