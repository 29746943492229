const namespace = 'components.Sidenav';

const keys = {
  DASHBOARD: `${namespace}.dashboard`,
  EAD: `${namespace}.ead`,
  MODULES: `${namespace}.modules`,
  COURSES: `${namespace}.courses`,
  VIDEOS: `${namespace}.videos`,
  QUESTIONS: `${namespace}.questions`,
  INDUSTRY: `${namespace}.industry`,
  PROMOTERS: `${namespace}.promoters`,
};

const en_US = {
  [keys.DASHBOARD]: 'Dashboard',
  [keys.EAD]: 'EAD',
  [keys.MODULES]: 'Modules',
  [keys.COURSES]: 'Courses',
  [keys.VIDEOS]: 'Videos',
  [keys.QUESTIONS]: 'Questions',
  [keys.INDUSTRY]: 'Industry',
  [keys.PROMOTERS]: 'Promoters',
};

const pt_BR = {
  [keys.DASHBOARD]: 'Dashboard',
  [keys.EAD]: 'EAD',
  [keys.MODULES]: 'Módulos',
  [keys.COURSES]: 'Cursos',
  [keys.VIDEOS]: 'Vídeos',
  [keys.QUESTIONS]: 'Questões',
  [keys.INDUSTRY]: 'Indústrias',
  [keys.PROMOTERS]: 'Promotores',
};

export { en_US, pt_BR };

export default keys;
