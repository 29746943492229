import React, { forwardRef } from 'react';
import { gql } from 'apollo-boost';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { getOr } from 'lodash/fp';
import { useIntl } from 'react-intl';
import { toaster } from 'baseui/toast';

import localeIds from './EadCoursesForm.locale';
import { deleteCacheByKey } from '../apolloClient';
import NotificationError from '../common/NotificationError';
import TextAreaField from '../common/TextAreaField';
import InputField from '../common/InputField';
import SelectField from '../common/SelectField';
import FormFooterButtons from '../common/FormFooterButtons';

const MODULE_OPTION = gql`
  fragment ModuleOption on CourseModule {
    _id
    description
  }
`;

const COURSE_FIELDS = gql`
  fragment CourseFields on Course {
    _id
    name
    description
    minScore
    modules {
      ...ModuleOption
    }
  }
  ${MODULE_OPTION}
`;

const CREATE_COURSE = gql`
  mutation CreateCourse($record: CreateOneCourseInput!) {
    course: createCourse(record: $record) {
      record {
        ...CourseFields
      }
    }
  }
  ${COURSE_FIELDS}
`;

const UPDATE_COURSE = gql`
  mutation UpdateCourse($record: UpdateByIdCourseInput!) {
    course: updateCourse(record: $record) {
      record {
        ...CourseFields
      }
    }
  }
  ${COURSE_FIELDS}
`;

export const GET_MODULES_FIELD_OPTIONS = gql`
  query GetModulesFieldOptions {
    courseModuleMany {
      ...ModuleOption
    }
  }
  ${MODULE_OPTION}
`;

const EadCoursesForm = forwardRef(({ course, onSuccess, modal }, ref) => {
  const [createCourse, { error: createError }] = useMutation(CREATE_COURSE, {
    refetchQueries: ['GetCourses'],
    update: store => {
      deleteCacheByKey(store, 'coursePagination');
    },
  });
  const [updateCourse, { error: updateError }] = useMutation(UPDATE_COURSE);
  const { data: modulesOptionsData, loading: isLoadingModulesOptions } = useQuery(GET_MODULES_FIELD_OPTIONS);
  const { formatMessage } = useIntl();
  const isEditing = Boolean(course);
  const modulesOptions = modulesOptionsData ? modulesOptionsData.courseModuleMany : [];
  const initialValues = {
    name: '',
    description: '',
    minScore: '',
    modules: [],
    ...(isEditing && {
      _id: course._id,
      name: course.name || '',
      description: course.description || '',
      minScore: typeof course.minScore === 'number' ? course.minScore : '',
      modules: course.modules || [],
    }),
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string(),
    minScore: Yup.number()
      .min(0)
      .max(100)
      .required(),
    modules: Yup.array(),
  });
  const handleSubmit = async ({ name, description, minScore, modules }, { setSubmitting }) => {
    try {
      const variables = {
        record: {
          name,
          description,
          minScore: Number(minScore),
          modules: modules.map(({ _id }) => _id),
          ...(isEditing && { _id: course._id }),
        },
      };
      const request = isEditing ? updateCourse : createCourse;
      const { data } = await request({ variables });
      toaster.positive(
        formatMessage({
          id: isEditing ? localeIds.NOTIFICATION_UPDATE_SUCCESS : localeIds.NOTIFICATION_CREATE_SUCCESS,
        }),
        { autoHideDuration: 5000 }
      );
      onSuccess(data.course.record);
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };
  const error = getOr(false, 'networkError.result', createError) || getOr(false, 'networkError.result', updateError);

  return (
    <>
      {error && (
        <NotificationError error={error}>{formatMessage({ id: localeIds.NOTIFICATION_ERROR })}</NotificationError>
      )}
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} ref={ref}>
        {({ handleSubmit, handleReset, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            {isEditing && (
              <Field name="_id" label={formatMessage({ id: localeIds.FIELD_ID })} component={InputField} disabled />
            )}
            <Field
              name="name"
              label={formatMessage({ id: localeIds.FIELD_NAME })}
              component={InputField}
              autoComplete="off"
            />
            <Field
              name="description"
              label={formatMessage({ id: localeIds.FIELD_DESCRIPTION })}
              component={TextAreaField}
              autoComplete="off"
            />
            <Field
              label={formatMessage({ id: localeIds.FIELD_MIN_SCORE })}
              name="minScore"
              endEnhancer="%"
              component={InputField}
              type="number"
              autoComplete="off"
              overrides={{
                Input: {
                  props: { min: 0, max: 100 },
                },
              }}
            />
            <Field
              label={formatMessage({ id: localeIds.FIELD_MODULES })}
              name="modules"
              component={SelectField}
              isLoading={isLoadingModulesOptions}
              options={modulesOptions}
              labelKey="description"
              valueKey="_id"
              multi
              autoComplete="off"
            />
            <FormFooterButtons
              onClear={handleReset}
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
              clearable={!isEditing}
              modal={modal}
            />
          </form>
        )}
      </Formik>
    </>
  );
});

export default EadCoursesForm;
