import React, { useState } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { useIntl } from 'react-intl';
import { useEad } from './EadContext';

import localeIds from './EadPromotersTable.locale';
import Table from '../common/Table';

const ENUM = {
  workerApproval: 'workerApproval',
};

const GET_PROMOTERS_COURSE = gql`
  query courseProgressPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyCourseProgressInput
    $sort: SortFindManyCourseProgressInput
  ) {
    courseProgressPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
      items {
        workerId
        worker {
          _id
          name
          user {
            validationCode {
              type
              isValidated
              dateValidated
            }
          }
        }
        courseId
        type
        score
        status
        retries
        updatedAt
        createdAt
      }
      pageInfo {
        currentPage
        pageCount
      }
      count
    }
  }
`;

const EadPromotersTable = () => {
  const {
    promoters: {
      pagination: { page, perPage },
      setPagination,
    },
  } = useEad();
  const [nextPage, setNextPage] = useState(page);
  const { data, loading } = useQuery(GET_PROMOTERS_COURSE, {
    variables: {
      page: nextPage,
      perPage,
      sort: 'UPDATEDAT_DESC',
      filter: { status: 'approved' },
    },
    onCompleted: () => setPagination({ page: nextPage, perPage }),
  });

  const { formatMessage, formatDate } = useIntl();
  const pageCount = getOr(1, 'courseProgressPagination.pageInfo.pageCount', data);
  const items = getOr([], 'courseProgressPagination.items', data);
  const columns = [
    {
      key: 'promoter',
      title: formatMessage({ id: localeIds.COLUMN_WORKER }),
      render: ({ worker }) => worker.name,
    },
    {
      key: 'retries',
      title: formatMessage({ id: localeIds.COLUMN_RETRIES }),
      render: ({ retries }) => retries || 1,
    },
    {
      key: 'date',
      title: formatMessage({ id: localeIds.COLUMN_DATE }),
      render: ({ updatedAt }) => {
        return formatDate(updatedAt, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
      },
    },
    {
      key: 'status',
      title: formatMessage({ id: localeIds.COLUMN_STATUS }),
      render: ({ status }) => formatMessage({ id: localeIds[`STATUS_${status.toUpperCase()}`] }),
    },

    {
      key: 'tshirt',
      title: formatMessage({ id: localeIds.T_SHIRT }),
      render: ({ worker }) => boughtTShirt(worker, formatMessage),
    },
    {
      key: 'tshirtReceivedAt',
      title: formatMessage({ id: localeIds.T_SHIRT_RECEIVED_AT }),
      render: ({ worker }) => {
        return receivedTShirtDate(worker, formatDate);
      },
    },
  ];

  const isPageDeleted = page > 1 && page > pageCount;
  if (isPageDeleted) {
    setNextPage(page - 1);
  }

  return (
    <>
      <Table
        columns={columns}
        items={items}
        itemKey="_id"
        isLoading={loading}
        page={page}
        pageCount={pageCount}
        onPageChange={setNextPage}
      />
    </>
  );
};

const boughtTShirt = ({ user }, formatMessage) => {
  const { validationCode } = user;
  if (validationCode.isValidate && validationCode.type === ENUM.workerApproval) {
    return formatMessage({ id: localeIds.YES });
  }
  return formatMessage({ id: localeIds.NOT });
};

const receivedTShirtDate = ({ user }, formatDate) => {
  const { validationCode } = user;

  if (validationCode.type === ENUM.workerApproval) {
    return formatDate(validationCode.createdAt, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }

  return '--';
};

export default EadPromotersTable;
