import React from 'react';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { getOr } from 'lodash/fp';
import { H4 } from 'baseui/typography';
import { toaster } from 'baseui/toast';
import { Spinner } from 'baseui/spinner';
import { Block } from 'baseui/block';
import { useIntl } from 'react-intl';

import localeIds from './IndustryVoucherForm.locale';
import userUser from '../app/useUser';
import TextAreaField from '../common/TextAreaField';
import InputField from '../common/InputField';
import FormFooterButtons from '../common/FormFooterButtons';

const INDUSTRY_BALANCE = gql`
  fragment IndustryBalance on AccountBalanceHistory {
    _id
    currentBalance
  }
`;

const GET_INDUSTRY_BALANCE = gql`
  query GetIndustryBalance($companyId: String) {
    balance: accountBalanceHistoryOne(filter: { companyId: $companyId }, sort: CREATEDAT_DESC) {
      ...IndustryBalance
    }
  }
  ${INDUSTRY_BALANCE}
`;

const CREATE_VOUCHER = gql`
  mutation CreateVoucher($companyId: String!, $amount: Int!, $observation: String, $userId: String!) {
    voucher: createVoucher(companyId: $companyId, amount: $amount, observations: $observation, userId: $userId) {
      _id
    }
  }
`;

const IndustryVoucherForm = ({ industry, onSuccess }) => {
  const { data, loading } = useQuery(GET_INDUSTRY_BALANCE, {
    skip: !industry._id,
    variables: { companyId: industry._id },
    fetchPolicy: 'network-only',
  });
  const { formatMessage } = useIntl();
  const [user] = userUser();
  const [createVoucher] = useMutation(CREATE_VOUCHER);

  if (loading) {
    return (
      <Block display="flex" justifyContent="center" marginTop="scale800">
        <Spinner size={96} />
      </Block>
    );
  }

  const balance = (getOr(0, 'balance.currentBalance', data) / 100).toFixed(2);

  return (
    <>
      <H4>Adicionar voucher</H4>
      <Formik
        initialValues={{
          industryName: industry.name,
          currentBalance: balance,
          amount: 0,
          observation: '',
        }}
        validationSchema={Yup.object().shape({
          amount: Yup.number().min(1),
          observation: Yup.string(),
        })}
        onSubmit={async ({ amount, observation }, { setSubmitting }) => {
          try {
            await createVoucher({
              variables: { userId: user.id, companyId: industry._id, amount: amount * 100, observation },
            });
            toaster.positive(formatMessage({ id: localeIds.TOAST_SUCCESS }), { autoHideDuration: 5000 });
            onSuccess();
          } catch (error) {
            toaster.negative(formatMessage({ id: localeIds.TOAST_ERROR }), { autoHideDuration: 5000 });
            console.error(error);
          }
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, handleReset, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Field
              label={formatMessage({ id: localeIds.FIELD_NAME })}
              name="industryName"
              component={InputField}
              disabled
            />
            <Field
              label={formatMessage({ id: localeIds.FIELD_CURRENT_BALANCE })}
              name="currentBalance"
              component={InputField}
              type="number"
              startEnhancer="R$"
              disabled
            />
            <Field
              label={formatMessage({ id: localeIds.FIELD_AMOUNT })}
              name="amount"
              component={InputField}
              type="number"
              startEnhancer="R$"
              disabled={isSubmitting}
            />
            <Field
              label={formatMessage({ id: localeIds.FIELD_OBSERVATION })}
              name="observation"
              component={TextAreaField}
              disabled={isSubmitting}
            />
            <FormFooterButtons
              clearable
              modal
              onClear={handleReset}
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
            />
          </form>
        )}
      </Formik>
    </>
  );
};

IndustryVoucherForm.defaultProps = {
  onSuccess: () => {},
};
export default IndustryVoucherForm;
