const namespace = 'ead.EadQuestionsPage';

const keys = {
  TITLE: `${namespace}.title`,
  CREATE_QUESTION: `${namespace}.createCourse`,
  EDIT_QUESTION: `${namespace}.editCourse`,
  DELETE_QUESTION: `${namespace}.deleteCourse`,
  CANCEL_BUTTON: `${namespace}.cancelButton`,
  DELETE_BUTTON: `${namespace}.deleteButton`,
};

const en_US = {
  [keys.TITLE]: 'Questions',
  [keys.CREATE_QUESTION]: 'Create question',
  [keys.EDIT_QUESTION]: 'Edit question',
  [keys.DELETE_QUESTION]: 'Delete question?',
  [keys.CANCEL_BUTTON]: 'Cancel',
  [keys.DELETE_BUTTON]: 'Delete',
};

const pt_BR = {
  [keys.TITLE]: 'Questões',
  [keys.CREATE_QUESTION]: 'Criar questão',
  [keys.EDIT_QUESTION]: 'Editar questão',
  [keys.DELETE_QUESTION]: 'Deletar questão?',
  [keys.CANCEL_BUTTON]: 'Cancelar',
  [keys.DELETE_BUTTON]: 'Deletar',
};

export { en_US, pt_BR };

export default keys;
