import React from 'react';
import { HeadingLevel, Heading } from 'baseui/heading';

const NotFoundPage = () => (
  <HeadingLevel>
    <Heading display="flex" justifyContent="center">
      404
    </Heading>
  </HeadingLevel>
);

export default NotFoundPage;
