import React from 'react';
import { withStyle } from 'baseui';
import { Select, StyledDropdownListItem } from 'baseui/select';
import { StyledList } from 'baseui/menu';
import { List, AutoSizer } from 'react-virtualized';

const ListItem = withStyle(StyledDropdownListItem, {
  paddingTop: 0,
  paddingBottom: 0,
  display: 'flex',
  alignItems: 'center',
});

const Container = withStyle(StyledList, { height: '400px' });

const VirtualList = React.forwardRef((props, ref) => {
  const children = React.Children.toArray(props.children);
  return (
    <Container ref={ref}>
      <AutoSizer>
        {({ width }) => (
          <List
            role={props.role}
            height={400}
            width={width}
            rowCount={props.children.length || 0}
            rowHeight={36}
            rowRenderer={({ index, key, style }) => {
              const { resetMenu, getItemLabel, ...childrenProps } = children[index].props;

              return (
                <ListItem key={key} style={style} {...childrenProps}>
                  {getItemLabel(childrenProps.item)}
                </ListItem>
              );
            }}
          ></List>
        )}
      </AutoSizer>
    </Container>
  );
});

const SelectVirtualized = props => {
  return <Select overrides={{ Dropdown: { component: VirtualList } }} {...props} />;
};

export default SelectVirtualized;
