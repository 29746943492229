import React, { useState, forwardRef } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { styled, withStyle } from 'baseui';
import { Modal, ModalHeader, ModalBody } from 'baseui/modal';
import { Label1 } from 'baseui/typography';
import { Button, KIND as BUTTON_KIND, SIZE as BUTTON_SIZE } from 'baseui/button';
import { StyledAction } from 'baseui/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { useIntl } from 'react-intl';

import localeIds from './EadQuestionsForm.locale';
import { deleteCacheByKey } from '../apolloClient';
import InputField from '../common/InputField';
import TextAreaField from '../common/TextAreaField';
import FormFooterButtons from '../common/FormFooterButtons';
import EadQuestionsOptionForm from './EadQuestionsOptionForm';
import { GET_QUESTIONS_FIELD_OPTIONS } from './EadVideosForm';

const QUESTION_FIELDS = gql`
  fragment QuestionFields on CourseQuestion {
    _id
    description
    minScore
    difficulty
    options {
      _id
      description
      correct
      reasons
    }
  }
`;

const CREATE_QUESTION = gql`
  mutation CreateQuestion($record: CreateOneCourseQuestionInput!) {
    question: createCourseQuestion(record: $record) {
      record {
        ...QuestionFields
      }
    }
  }
  ${QUESTION_FIELDS}
`;

export const UPDATE_QUESTION = gql`
  mutation UpdateQuestion($record: UpdateByIdCourseQuestionInput!) {
    question: updateCourseQuestion(record: $record) {
      record {
        ...QuestionFields
      }
    }
  }
  ${QUESTION_FIELDS}
`;

export const DELETE_QUESTION_OPTION = gql`
  mutation DeleteQuestionOption($_id: MongoID!) {
    courseQuestionOptionRemoveById(_id: $_id) {
      recordId
    }
  }
`;

export const StyledOption = styled('div', ({ $theme, $isCorrect }) => ({
  borderRadius: $theme.borders.buttonBorderRadius,
  padding: $theme.sizing.scale400,
  marginTop: $theme.sizing.scale400,
  marginBottom: $theme.sizing.scale400,
  display: 'flex',
  justifyContent: 'space-between',
  ...($isCorrect && {
    backgroundColor: $theme.colors.notificationPositiveBackground,
    color: $theme.colors.notificationPositiveText,
  }),
}));

const StyledOptionAction = withStyle(StyledAction, {
  color: 'inherit',
});

const EadQuestionForm = forwardRef(
  ({ question, onOptionCreated, onOptionUpdated, onCreated, onUpdated, modal }, ref) => {
    const [showQuestionOptionForm, setShowQuestionOptionForm] = useState(false);
    const [selectedQuestionOption, setSelectedQuestionOption] = useState(null);
    const [createQuestion] = useMutation(CREATE_QUESTION, {
      refetchQueries: ['GetQuestions', { query: GET_QUESTIONS_FIELD_OPTIONS }],
      update: store => {
        deleteCacheByKey(store, 'courseQuestionPagination');
      },
    });
    const { formatMessage } = useIntl();
    const [updateQuestion] = useMutation(UPDATE_QUESTION);
    const [deleteQuestionOption] = useMutation(DELETE_QUESTION_OPTION);
    const isEditing = Boolean(question);
    const initialValues = {
      description: '',
      options: [],
      ...(isEditing && {
        _id: question._id,
        description: question.description || '',
        options: question.options || [],
      }),
    };
    const validationSchema = Yup.object().shape({
      description: Yup.string().required(),
    });
    const handleSubmit = async ({ description }, { setSubmitting }) => {
      try {
        const variables = {
          record: {
            description,
            ...(isEditing && { _id: question._id }),
          },
        };
        const request = isEditing ? updateQuestion : createQuestion;
        const { data } = await request({ variables });
        const callback = isEditing ? onUpdated : onCreated;
        callback(data.question.record);
      } catch (error) {
        // TODO implement error feedback
        console.error(error);
      }
      setSubmitting(false);
    };

    return (
      <>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          ref={ref}
        >
          {({ handleSubmit, handleReset, isSubmitting }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              {isEditing && (
                <Field name="_id" label={formatMessage({ id: localeIds.FIELD_ID })} component={InputField} disabled />
              )}
              <Field
                name="description"
                label={formatMessage({ id: localeIds.FIELD_DESCRIPTION })}
                component={TextAreaField}
                autoComplete="off"
              />
              {isEditing && (
                <>
                  <Label1 style={{ marginBottom: '8px' }}>{formatMessage({ id: localeIds.LABEL_OPTIONS })}</Label1>
                  {question.options.map((option, index) => (
                    <StyledOption key={option._id} $isCorrect={option.correct}>
                      <div>
                        {index + 1}. {option.description}
                      </div>
                      <div>
                        <StyledOptionAction
                          type="button"
                          onClick={() => {
                            setSelectedQuestionOption(option);
                            setShowQuestionOptionForm(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </StyledOptionAction>
                        <StyledOptionAction
                          type="button"
                          onClick={async () => {
                            const { data } = await updateQuestion({
                              variables: {
                                record: {
                                  _id: question._id,
                                  options: question.options
                                    .filter(({ _id }) => _id !== option._id)
                                    .map(({ _id }) => _id),
                                },
                              },
                            });
                            deleteQuestionOption({ variables: { _id: option._id } });
                            onOptionUpdated(data.question.record);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </StyledOptionAction>
                      </div>
                    </StyledOption>
                  ))}
                  <Button
                    type="button"
                    kind={BUTTON_KIND.secondary}
                    size={BUTTON_SIZE.compact}
                    onClick={() => setShowQuestionOptionForm(true)}
                    overrides={{ BaseButton: { style: { width: '100%' } } }}
                  >
                    {formatMessage({ id: localeIds.BUTTON_ADD_OPTION })}
                  </Button>
                </>
              )}
              <FormFooterButtons
                onClear={handleReset}
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                clearable={!isEditing}
                modal={modal}
              />
            </form>
          )}
        </Formik>
        <Modal isOpen={showQuestionOptionForm} onClose={() => setShowQuestionOptionForm(false)}>
          <ModalHeader>Question option</ModalHeader>
          <ModalBody>
            {isEditing && (
              <EadQuestionsOptionForm
                question={question}
                questionOption={selectedQuestionOption}
                onCreated={async questionOption => {
                  setShowQuestionOptionForm(false);
                  setSelectedQuestionOption(null);
                  const { data } = await updateQuestion({
                    variables: {
                      record: {
                        _id: question._id,
                        options: [...question.options.map(({ _id }) => _id), questionOption._id],
                      },
                    },
                  });
                  onOptionCreated(data.question.record);
                }}
                onUpdated={questionOption => {
                  setShowQuestionOptionForm(false);
                  setSelectedQuestionOption(null);
                  const updateQuestion = {
                    ...question,
                    options: question.options.map(option => {
                      if (option._id === questionOption._id) {
                        return questionOption;
                      }

                      return option;
                    }),
                  };
                  onOptionUpdated(updateQuestion);
                }}
              />
            )}
          </ModalBody>
        </Modal>
      </>
    );
  }
);

EadQuestionForm.defaultProps = {
  onCreated: () => {},
  onUpdated: () => {},
};

export default EadQuestionForm;
