import React, { forwardRef } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { STYLE_TYPE as CHECKBOX_STYLE_TYPE } from 'baseui/checkbox';
import { Button, KIND as BUTTON_KIND, SIZE as BUTTON_SIZE } from 'baseui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

import { useIntl } from 'react-intl';

import { StyledOption } from './EadQuestionsForm';
import localeIds from './EadQuestionsOptionForm.locale';
import InputField from '../common/InputField';
import TextAreaField from '../common/TextAreaField';
import CheckboxField from '../common/CheckboxField';
import FormFooterButtons from '../common/FormFooterButtons';

const QUESTION_OPTION_FIELDS = gql`
  fragment QuestionOptionFields on CourseQuestionOption {
    _id
    description
    correct
    reasons
  }
`;

const CREATE_QUESTION_OPTION = gql`
  mutation CreateQuestionOption($record: CreateOneCourseQuestionOptionInput!) {
    questionOption: createCourseQuestionOption(record: $record) {
      record {
        ...QuestionOptionFields
      }
    }
  }
  ${QUESTION_OPTION_FIELDS}
`;

const UPDATE_QUESTION_OPTION = gql`
  mutation UpdateQuestionOption($record: UpdateByIdCourseQuestionOptionInput!) {
    questionOption: updateCourseQuestionOption(record: $record) {
      record {
        ...QuestionOptionFields
      }
    }
  }
  ${QUESTION_OPTION_FIELDS}
`;

const EadQuestionsOptionForm = forwardRef(({ questionOption, onCreated, onUpdated, modal }, ref) => {
  const [createQuestionOption] = useMutation(CREATE_QUESTION_OPTION);
  const [updateQuestionOption] = useMutation(UPDATE_QUESTION_OPTION);
  const { formatMessage } = useIntl();
  const isEditing = Boolean(questionOption);
  const initialValues = {
    description: '',
    correct: false,
    reasons: [],
    ...(isEditing && {
      _id: questionOption._id,
      description: questionOption.description,
      correct: Boolean(questionOption.correct),
      reasons: questionOption.reasons || [],
    }),
  };
  const validationSchema = Yup.object().shape({
    description: Yup.string().required(),
    correct: Yup.bool().required(),
    reasons: Yup.array(),
  });
  const handleSubmit = async ({ description, correct, reasons }, { setSubmitting }) => {
    try {
      const variables = {
        record: {
          description,
          correct,
          reasons,
          ...(isEditing && { _id: questionOption._id }),
        },
      };
      const request = isEditing ? updateQuestionOption : createQuestionOption;
      const { data: questionOptionData } = await request({ variables });
      const callback = isEditing ? onUpdated : onCreated;
      callback(questionOptionData.questionOption.record);
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} ref={ref}>
      {({ handleSubmit, handleReset, isSubmitting, values }) => (
        <form onSubmit={handleSubmit}>
          {isEditing && (
            <Field name="_id" label={formatMessage({ id: localeIds.FIELD_ID })} component={InputField} disabled />
          )}
          <Field
            name="description"
            label={formatMessage({ id: localeIds.FIELD_DESCRIPTION })}
            component={TextAreaField}
          />
          <FieldArray name="reasons">
            {({ push, remove }) => (
              <>
                {values.reasons.map((reason, index) => (
                  <div key={index} style={{ position: 'relative' }}>
                    <Field
                      name={`reasons.${index}`}
                      label={formatMessage({ id: localeIds.FIELD_REASON })}
                      component={TextAreaField}
                    />
                    <StyledOption
                      onClick={() => remove(index)}
                      style={{ position: 'absolute', top: '-16px', right: 0 }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </StyledOption>
                  </div>
                ))}
                <Button
                  type="button"
                  kind={BUTTON_KIND.secondary}
                  size={BUTTON_SIZE.compact}
                  onClick={() => push('')}
                  overrides={{ BaseButton: { style: { width: '100%', marginBottom: '16px' } } }}
                >
                  {formatMessage({ id: localeIds.BUTTON_ADD_REASON })}
                </Button>
              </>
            )}
          </FieldArray>
          <Field
            name="correct"
            checkboxLabel={formatMessage({ id: localeIds.FIELD_CORRECT })}
            component={CheckboxField}
            checkmarkType={CHECKBOX_STYLE_TYPE.toggle}
          />
          <FormFooterButtons
            onClear={handleReset}
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            clearable={!isEditing}
            modal={modal}
          />
        </form>
      )}
    </Formik>
  );
});

export default EadQuestionsOptionForm;
