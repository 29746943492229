const namespace = 'ead.EadModulesForm';

const keys = {
  FIELD_ID: `${namespace}.field.id`,
  FIELD_DESCRIPTION: `${namespace}.field.description`,
  FIELD_MIN_SCORE: `${namespace}.field.minScore`,
  FIELD_VIDEOS: `${namespace}.field.videos`,
};

const en_US = {
  [keys.FIELD_ID]: 'ID',
  [keys.FIELD_DESCRIPTION]: 'Description',
  [keys.FIELD_MIN_SCORE]: 'Minimal Score',
  [keys.FIELD_VIDEOS]: 'Modules',
};

const pt_BR = {
  [keys.FIELD_ID]: 'ID',
  [keys.FIELD_DESCRIPTION]: 'Descrição',
  [keys.FIELD_MIN_SCORE]: 'Pontuação Mínima',
  [keys.FIELD_VIDEOS]: 'Módulos',
};

export { en_US, pt_BR };

export default keys;
