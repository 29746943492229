import React from 'react';
import { useIntl } from 'react-intl';

import localeIds from './EadPromotersPage.locale';
import EadPromotersTable from './EadPromotersTable';
import PageLayout from '../app/PageLayout';

const EadPromotersPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageLayout title={formatMessage({ id: localeIds.TITLE })}>
        <EadPromotersTable />
      </PageLayout>
    </>
  );
};

export default EadPromotersPage;
