const namespace = 'ead.EadQuestionsForm';

const keys = {
  FIELD_ID: `${namespace}.field.id`,
  FIELD_DESCRIPTION: `${namespace}.field.description`,
  LABEL_OPTIONS: `${namespace}.label.options`,
  BUTTON_ADD_OPTION: `${namespace}.button.addOption`,
};

const en_US = {
  [keys.FIELD_ID]: 'ID',
  [keys.FIELD_DESCRIPTION]: 'Description',
  [keys.LABEL_OPTIONS]: 'Options',
  [keys.BUTTON_ADD_OPTION]: 'Add option',
};

const pt_BR = {
  [keys.FIELD_ID]: 'ID',
  [keys.FIELD_DESCRIPTION]: 'Descrição',
  [keys.LABEL_OPTIONS]: 'Opções',
  [keys.BUTTON_ADD_OPTION]: 'Adicionar opção',
};

export { en_US, pt_BR };

export default keys;
