import React, { useState } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import { useIntl } from 'react-intl';

import localeIds from './EadQuestionsTable.locale';
import { useEad } from './EadContext';
import Table, { TableAction } from '../common/Table';

const QUESTION_ROW = gql`
  fragment QuestionRow on CourseQuestion {
    _id
    description
    minScore
    difficulty
    options {
      _id
      description
      correct
      reasons
    }
  }
`;

const GET_QUESTIONS = gql`
  query GetQuestions(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyCourseQuestionInput
    $sort: SortFindManyCourseQuestionInput
  ) {
    courseQuestionPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
      count
      items {
        ...QuestionRow
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
  ${QUESTION_ROW}
`;

const EadQuestionsTable = ({ onEdit, onDelete }) => {
  const {
    questions: {
      pagination: { page, perPage },
      setPagination,
    },
  } = useEad();
  const { formatMessage } = useIntl();
  const [nextPage, setNextPage] = useState(page);
  const { data, loading } = useQuery(GET_QUESTIONS, {
    variables: { page: nextPage, perPage },
    onCompleted: () => setPagination({ page: nextPage, perPage }),
  });
  const pageCount = getOr(1, 'courseQuestionPagination.pageInfo.pageCount', data);
  const items = getOr([], 'courseQuestionPagination.items', data);
  const columns = [
    {
      key: 'id',
      title: formatMessage({ id: localeIds.COLUMN_ID }),
      render: ({ _id }) => _id,
    },
    {
      key: 'description',
      title: formatMessage({ id: localeIds.COLUMN_DESCRIPTION }),
      render: ({ description }) => description,
    },
    {
      key: 'minScore',
      title: formatMessage({ id: localeIds.COLUMN_MIN_SCORE }),
      render: ({ minScore }) => (typeof minScore === 'number' ? `${minScore}%` : null),
    },
    {
      key: 'difficulty',
      title: formatMessage({ id: localeIds.COLUMN_DIFFICULTY }),
      render: ({ difficulty }) => (typeof difficulty === 'number' ? difficulty : null),
    },
    {
      key: 'actions',
      title: formatMessage({ id: localeIds.COLUMN_ACTIONS }),
      render: item => (
        <>
          <TableAction onClick={() => onEdit(item)}>
            <FontAwesomeIcon icon={faEdit} />
          </TableAction>
          <TableAction>
            <FontAwesomeIcon icon={faTrashAlt} onClick={() => onDelete(item)} />
          </TableAction>
        </>
      ),
    },
  ];
  const isPageDeleted = page > 1 && page > pageCount;
  if (isPageDeleted) {
    setNextPage(page - 1);
  }

  return (
    <Table
      data-testid="ead-questions-table"
      columns={columns}
      items={items}
      itemKey="_id"
      isLoading={loading}
      page={page}
      pageCount={pageCount}
      onPageChange={setNextPage}
    />
  );
};

export default EadQuestionsTable;
