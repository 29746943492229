const namespace = 'ead.EadCoursesTable';

const keys = {
  COLUMN_NAME: `${namespace}.column.name`,
  COLUMN_DESCRIPTION: `${namespace}.column.description`,
  COLUMN_MIN_SCORE: `${namespace}.column.minScore`,
  COLUMN_ACTIONS: `${namespace}.column.actions`,
};

const en_US = {
  [keys.COLUMN_NAME]: 'Name',
  [keys.COLUMN_DESCRIPTION]: 'Description',
  [keys.COLUMN_MIN_SCORE]: 'Minimal Score',
  [keys.COLUMN_ACTIONS]: 'Actions',
};

const pt_BR = {
  [keys.COLUMN_NAME]: 'Nome',
  [keys.COLUMN_DESCRIPTION]: 'Descrição',
  [keys.COLUMN_MIN_SCORE]: 'Pontuação Mínima',
  [keys.COLUMN_ACTIONS]: 'Ações',
};

export { en_US, pt_BR };

export default keys;
