import React, { useState } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import { StyledLink } from 'baseui/link';
import { useIntl } from 'react-intl';

import localeIds from './EadVideosTable.locale';
import { useEad } from './EadContext';
import { vimeoBaseUrl } from '../utils/getVimeoData';
import Table, { TableAction } from '../common/Table';

const VIDEO_ROW = gql`
  fragment VideoRow on CourseVideo {
    _id
    title
    url
    minNumQuestions
    videoUri
    length
    createdAt
    updatedAt
    questions {
      _id
      description
    }
  }
`;

export const GET_VIDEOS = gql`
  query GetVideos(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyCourseVideoInput
    $sort: SortFindManyCourseVideoInput
  ) {
    courseVideoPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
      count
      items {
        ...VideoRow
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
  ${VIDEO_ROW}
`;

const EadVideosTable = ({ onEdit, onDelete }) => {
  const {
    videos: {
      pagination: { page, perPage },
      setPagination,
    },
  } = useEad();
  const [nextPage, setNextPage] = useState(page);
  const { data, loading } = useQuery(GET_VIDEOS, {
    variables: { page: nextPage, perPage },
    onCompleted: () => setPagination({ page: nextPage, perPage }),
  });
  const { formatMessage } = useIntl();
  const pageCount = getOr(1, 'courseVideoPagination.pageInfo.pageCount', data);
  const items = getOr([], 'courseVideoPagination.items', data);
  const columns = [
    {
      key: 'title',
      title: formatMessage({ id: localeIds.COLUMN_TITLE }),
      render: ({ title }) => title,
    },
    {
      key: 'url',
      title: formatMessage({ id: localeIds.COLUMN_VIDEO_URL }),
      render: ({ url: id }) => {
        const vimeoUrl = `${vimeoBaseUrl}${id}`;
        return (
          <StyledLink rel="noopener noreferrer" target="_blank" href={vimeoUrl}>
            {vimeoUrl}
          </StyledLink>
        );
      },
    },
    {
      key: 'videoUri',
      title: formatMessage({ id: localeIds.COLUMN_VIDEO_URI }),
      render: ({ videoUri: id }) => {
        const videoUri = `${id}`;
        return (
          <StyledLink rel="noopener noreferrer" target="_blank" href={videoUri}>
            {videoUri.slice(0, 30).concat('...')}
          </StyledLink>
        );
      },
    },
    {
      key: 'minNumQuestions',
      title: formatMessage({ id: localeIds.COLUMN_MIN_NUM_QUESTIONS }),
      render: ({ minNumQuestions }) => minNumQuestions,
    },
    {
      key: 'actions',
      title: formatMessage({ id: localeIds.COLUMN_ACTIONS }),
      render: item => (
        <>
          <TableAction onClick={() => onEdit(item)}>
            <FontAwesomeIcon icon={faEdit} />
          </TableAction>
          <TableAction>
            <FontAwesomeIcon icon={faTrashAlt} onClick={() => onDelete(item)} />
          </TableAction>
        </>
      ),
    },
  ];
  const isPageDeleted = page > 1 && page > pageCount;
  if (isPageDeleted) {
    setNextPage(page - 1);
  }

  return (
    <Table
      columns={columns}
      items={items}
      itemKey="_id"
      isLoading={loading}
      page={page}
      pageCount={pageCount}
      onPageChange={setNextPage}
    />
  );
};

export default EadVideosTable;
