const namespace = 'ead.EadVideosTable';

const keys = {
  COLUMN_TITLE: `${namespace}.column.title`,
  COLUMN_VIDEO_URL: `${namespace}.column.videoUrl`,
  COLUMN_VIDEO_URI: `${namespace}.column.videoUri`,
  COLUMN_MIN_NUM_QUESTIONS: `${namespace}.column.minNumQuestions`,
  COLUMN_ACTIONS: `${namespace}.column.actions`,
};

const en_US = {
  [keys.COLUMN_TITLE]: 'Title',
  [keys.COLUMN_VIDEO_URL]: 'Video URL',
  [keys.COLUMN_VIDEO_URI]: 'Video URI',
  [keys.COLUMN_MIN_NUM_QUESTIONS]: 'Minimum number of questions',
  [keys.COLUMN_ACTIONS]: 'Actions',
};

const pt_BR = {
  [keys.COLUMN_TITLE]: 'Título',
  [keys.COLUMN_VIDEO_URL]: 'Vídeo URL',
  [keys.COLUMN_VIDEO_URI]: 'Vídeo URI',
  [keys.COLUMN_MIN_NUM_QUESTIONS]: 'Número mínimo de questões',
  [keys.COLUMN_ACTIONS]: 'Ações',
};

export { en_US, pt_BR };

export default keys;
