const namespace = 'industry.IndustryVoucherForm';

const keys = {
  TOAST_SUCCESS: `${namespace}.toast.success`,
  TOAST_ERROR: `${namespace}.toast.error`,
  FIELD_NAME: `${namespace}.field.name`,
  FIELD_CURRENT_BALANCE: `${namespace}.field.currentBalance`,
  FIELD_AMOUNT: `${namespace}.field.amount`,
  FIELD_OBSERVATION: `${namespace}.field.observation`,
};

const en_US = {
  [keys.TOAST_SUCCESS]: 'Voucher Created!',
  [keys.TOAST_ERROR]: 'Unable to generate voucher',
  [keys.FIELD_NAME]: 'Name',
  [keys.FIELD_CURRENT_BALANCE]: 'Balance',
  [keys.FIELD_AMOUNT]: 'Voucher Amount',
  [keys.FIELD_OBSERVATION]: 'Observation',
};

const pt_BR = {
  [keys.TOAST_SUCCESS]: 'Voucher criado com sucesso!',
  [keys.TOAST_ERROR]: 'Não foi possível gerar o voucher.',
  [keys.FIELD_NAME]: 'Nome',
  [keys.FIELD_CURRENT_BALANCE]: 'Saldo',
  [keys.FIELD_AMOUNT]: 'Valor do voucher',
  [keys.FIELD_OBSERVATION]: 'Observação',
};

export { en_US, pt_BR };

export default keys;
