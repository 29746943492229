import React from 'react';
import { Tag, VARIANT } from 'baseui/tag';

import useConstants from '../app/useConstants';

const IndustryStatusTag = ({ status }) => {
  const { parseCompanyStatus } = useConstants();
  const kind = parseCompanyStatus(status).tagKind;
  const label = parseCompanyStatus(status).label;

  return (
    <Tag closeable={false} kind={kind} variant={VARIANT.outlined}>
      {label}
    </Tag>
  );
};

export default IndustryStatusTag;
