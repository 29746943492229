export const vimeoBaseUrl = 'https://vimeo.com/';

export function getVimeoUrlById(vimeoId) {
  return `${vimeoBaseUrl}${vimeoId}`;
}

export function getVimeoIdByUrl(vimeoUrl) {
  return vimeoUrl.replace(vimeoBaseUrl, '');
}

/**
 * Check to see whether the value is a number.
 *
 * @see https://github.com/vimeo/player.js/blob/master/src/lib/functions.js
 * @param {*} value The value to check.
 * @param {boolean} integer Check if the value is an integer.
 * @return {boolean}
 */
function isInteger(value) {
  // eslint-disable-next-line eqeqeq
  return !isNaN(parseFloat(value)) && isFinite(value) && Math.floor(value) == value;
}

/**
 * @see https://github.com/vimeo/player.js/blob/master/src/lib/functions.js
 * @param {string} url
 */
function isVimeoUrl(url) {
  return /^(https?:)?\/\/((player|www)\.)?vimeo\.com(?=$|\/)/.test(url);
}

/**
 * @see https://github.com/vimeo/player.js/blob/master/src/lib/embed.js
 * @param {*} videoIdOrUrl
 */
function getVimeoData(videoIdOrUrl) {
  return new Promise((resolve, reject) => {
    const videoUrl = isInteger(videoIdOrUrl) ? `${vimeoBaseUrl}${videoIdOrUrl}` : videoIdOrUrl;

    if (!isVimeoUrl(videoUrl)) {
      throw new TypeError(`“${videoUrl}” is not a vimeo.com url.`);
    }

    const url = `${vimeoBaseUrl}api/oembed.json?url=${encodeURIComponent(videoUrl)}`;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);

    xhr.onload = function() {
      if (xhr.status === 404) {
        reject(new Error(`“${videoUrl}” was not found.`));
        return;
      }

      if (xhr.status === 403) {
        reject(new Error(`“${videoUrl}” is not embeddable.`));
        return;
      }

      try {
        const json = JSON.parse(xhr.responseText);
        // Check api response for 403 on oembed
        if (json.domain_status_code === 403) {
          // We still want to create the embed to give users visual feedback
          reject(new Error(`“${videoUrl}” is not embeddable.`));
          return;
        }

        resolve(json);
      } catch (error) {
        reject(error);
      }
    };

    xhr.onerror = function() {
      const status = xhr.status ? ` (${xhr.status})` : '';
      reject(new Error(`There was an error fetching the embed code from Vimeo${status}.`));
    };

    xhr.send();
  });
}

export default getVimeoData;
