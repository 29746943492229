import { gql } from 'apollo-boost';

const typeDefs = gql`
  type AppUser {
    id: String!
    name: String!
    email: String!
  }

  extend type Query {
    theme: String!
    locale: String!
    user: AppUser
  }
`;

const resolvers = {};

export { typeDefs, resolvers };
