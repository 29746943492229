const namespace = 'ead.EadCoursesPage';

const keys = {
  TITLE: `${namespace}.title`,
  CREATE_COURSE: `${namespace}.createCourse`,
  EDIT_COURSE: `${namespace}.editCourse`,
  DELETE_COURSE: `${namespace}.deleteCourse`,
  CANCEL_BUTTON: `${namespace}.cancelButton`,
  DELETE_BUTTON: `${namespace}.deleteButton`,
  LABEL_ID: `${namespace}.label.id`,
  LABEL_NAME: `${namespace}.label.name`,
  LABEL_DESCRIPTION: `${namespace}.label.description`,
  LABEL_MIN_SCORE: `${namespace}.label.minScore`,
};

const en_US = {
  [keys.TITLE]: 'Courses',
  [keys.CREATE_COURSE]: 'Create course',
  [keys.EDIT_COURSE]: 'Edit course',
  [keys.DELETE_COURSE]: 'Delete course?',
  [keys.CANCEL_BUTTON]: 'Cancel',
  [keys.DELETE_BUTTON]: 'Delete',
  [keys.LABEL_ID]: 'ID',
  [keys.LABEL_NAME]: 'Name',
  [keys.LABEL_DESCRIPTION]: 'Description',
  [keys.LABEL_MIN_SCORE]: 'Minimal Score',
};

const pt_BR = {
  [keys.TITLE]: 'Cursos',
  [keys.CREATE_COURSE]: 'Criar curso',
  [keys.EDIT_COURSE]: 'Editar curso',
  [keys.DELETE_COURSE]: 'Deletar curso?',
  [keys.CANCEL_BUTTON]: 'Cancelar',
  [keys.DELETE_BUTTON]: 'Deletar',
  [keys.LABEL_ID]: 'ID',
  [keys.LABEL_NAME]: 'Nome',
  [keys.LABEL_DESCRIPTION]: 'Descrição',
  [keys.LABEL_MIN_SCORE]: 'Pontuação Mínima',
};

export { en_US, pt_BR };

export default keys;
