const namespace = 'components.LocaleButton';

const keys = {
  PORTUGUESE: `${namespace}.portuguese`,
  ENGLISH: `${namespace}.english`,
};

const en_US = {
  [keys.PORTUGUESE]: 'BR',
  [keys.ENGLISH]: 'US',
};

const pt_BR = {
  [keys.PORTUGUESE]: 'BR',
  [keys.ENGLISH]: 'US',
};

export { en_US, pt_BR };

export default keys;
