import React from 'react';
import { Redirect } from 'react-router-dom';

import useUser from './useUser';

const PrivateRoute = ({ component: Component, ...props }) => {
  const [user] = useUser();
  const isAuthenticated = Boolean(user);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return <Component {...props} />;
};

export default PrivateRoute;
