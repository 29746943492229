import React from 'react';
import { FormControl } from 'baseui/form-control';
import { Textarea } from 'baseui/textarea';
import PropTypes from 'prop-types';

const TextAreaField = ({
  field,
  form: { touched, errors, setFieldValue, submitCount },
  label,
  caption,
  onChange,
  ...props
}) => {
  const hasError = submitCount > 0 && errors[field.name];
  const handleChange = event => {
    setFieldValue(field.name, event.currentTarget.value);
    onChange(event.currentTarget.value);
  };

  return (
    <FormControl label={label} caption={caption} error={hasError && errors[field.name]}>
      <Textarea {...field} {...props} value={field.value} onChange={handleChange} error={hasError} />
    </FormControl>
  );
};

TextAreaField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  caption: PropTypes.string,
  onChange: PropTypes.func,
};

TextAreaField.defaultProps = {
  onChange: () => {},
};

export default TextAreaField;
