import React, { useState } from 'react';
import { Notification, KIND as NOTIFICATION_KIND } from 'baseui/notification';
import { Button, KIND as BUTTON_KIND, SIZE as BUTTON_SIZE } from 'baseui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Inspector } from 'react-inspector';

import useTheme from '../app/useTheme';

const NotificationError = ({ children, error }) => {
  const [showError, setShowError] = useState(false);
  const [theme] = useTheme();
  const inspectorTheme = theme === 'dark' ? 'chromeDark' : 'chromeLight';

  return (
    <Notification
      closeable
      kind={NOTIFICATION_KIND.negative}
      overrides={{
        Body: { style: { width: '100%', boxSizing: 'border-box' } },
        InnerContainer: { style: { width: '100%' } },
      }}
    >
      <div>{children}</div>
      {error && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button size={BUTTON_SIZE.compact} kind={BUTTON_KIND.minimal} onClick={() => setShowError(!showError)}>
            <FontAwesomeIcon icon={showError ? faChevronUp : faChevronDown} />
          </Button>
        </div>
      )}
      {showError && (
        <div style={{ marginTop: 16 }}>
          <Inspector
            theme={inspectorTheme}
            data={error}
            expandPaths={['$', '$.errors', '$.errors.*', '$.errors.*.message']}
          />
        </div>
      )}
    </Notification>
  );
};

export default NotificationError;
