import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider, LocaleProvider } from 'baseui';
import { ToasterContainer, PLACEMENT as TOAST_PLACEMENT } from 'baseui/toast';
import { IntlProvider } from 'react-intl';
import { setLocale as setYupLocale } from 'yup';

import { locales, baseUiLocales, yupLocales } from '../i18n';
import themes from '../themes';
import useLocale from './useLocale';
import useTheme from './useTheme';
import useUser from './useUser';
import PrivateApp from './PrivateApp';
import GuestApp from './GuestApp';

const engine = new Styletron();

const App = () => {
  const [locale] = useLocale();
  const [theme] = useTheme();
  const [user] = useUser();
  const isAuthenticated = Boolean(user);

  useEffect(() => {
    setYupLocale(yupLocales[locale]);
  }, [locale]);

  return (
    <IntlProvider locale={locale} messages={locales[locale]}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={themes[theme]}>
          <LocaleProvider locale={baseUiLocales[locale]}>
            <BrowserRouter>{isAuthenticated ? <PrivateApp /> : <GuestApp />}</BrowserRouter>
            <ToasterContainer placement={TOAST_PLACEMENT.bottomRight} />
          </LocaleProvider>
        </BaseProvider>
      </StyletronProvider>
    </IntlProvider>
  );
};

export default App;
