const namespace = 'ead.EadVideosForm';

const keys = {
  FIELD_ID: `${namespace}.field.id`,
  FIELD_URL: `${namespace}.field.url`,
  FIELD_URI: `${namespace}.field.uri`,
  FIELD_MIN_NUM_QUESTIONS: `${namespace}.field.minNumQuestions`,
  FIELD_QUESTIONS: `${namespace}.field.questions`,
};

const en_US = {
  [keys.FIELD_ID]: 'ID',
  [keys.FIELD_URL]: 'Video URL',
  [keys.FIELD_URI]: 'Video URI',
  [keys.FIELD_MIN_NUM_QUESTIONS]: 'Minimum number of questions',
  [keys.FIELD_QUESTIONS]: 'Questions',
};

const pt_BR = {
  [keys.FIELD_ID]: 'ID',
  [keys.FIELD_URL]: 'URL do vídeo',
  [keys.FIELD_URI]: 'URI do vídeo',
  [keys.FIELD_MIN_NUM_QUESTIONS]: 'Número mínimo de questões',
  [keys.FIELD_QUESTIONS]: 'Questões',
};

export { en_US, pt_BR };

export default keys;
