const namespace = 'components.EadPromotersTable';

const keys = {
  COLUMN_DATE: `${namespace}.column.date`,
  COLUMN_WORKER: `${namespace}.column.worker`,
  T_SHIRT: `${namespace}.column.tshirt`,
  COLUMN_COMPANY: `${namespace}.column.company`,
  COLUMN_STATUS: `${namespace}.column.status`,
  COLUMN_RETRIES: `${namespace}.column.retries`,
  INPUT_NAME: `${namespace}.column.input`,
  STATUS_STARTED: `${namespace}.status.started`,
  STATUS_PENDING: `${namespace}.status.pending`,
  STATUS_APPROVED: `${namespace}.status.approved`,
  YES: `${namespace}.status.yes`,
  NOT: `${namespace}.status.not`,
  T_SHIRT_RECEIVED_AT: `${namespace}.column.receivedAt`,
  COLUMN_ACTIONS: `${namespace}.column.actions`,
};

const en_US = {
  [keys.COLUMN_DATE]: 'Finished at',
  [keys.COLUMN_WORKER]: 'Promoter',
  [keys.T_SHIRT]: 'Received tshirt',
  [keys.COLUMN_RETRIES]: 'Course retries',
  [keys.COLUMN_STATUS]: 'Status',
  [keys.INPUT_NAME]: 'Search by name',
  [keys.STATUS_STARTED]: 'Started',
  [keys.STATUS_PENDING]: 'Pending',
  [keys.STATUS_APPROVED]: 'Approved',
  [keys.YES]: 'Yes',
  [keys.NOT]: 'Not',
  [keys.T_SHIRT_RECEIVED_AT]: 'Received at',
  [keys.COLUMN_ACTIONS]: 'Actions',
};

const pt_BR = {
  [keys.COLUMN_DATE]: 'Finalizado em',
  [keys.COLUMN_WORKER]: 'Repositor',
  [keys.T_SHIRT]: 'Recebeu a camiseta',
  [keys.COLUMN_RETRIES]: 'Tentativas curso',
  [keys.COLUMN_STATUS]: 'Status',
  [keys.INPUT_NAME]: 'Pesquise pelo nome',
  [keys.STATUS_STARTED]: 'Iniciado',
  [keys.STATUS_PENDING]: 'Pendente',
  [keys.STATUS_APPROVED]: 'Aprovado',
  [keys.YES]: 'Sim',
  [keys.NOT]: 'Não',
  [keys.T_SHIRT_RECEIVED_AT]: 'Recebida em',
  [keys.COLUMN_ACTIONS]: 'Ações',
};

export { en_US, pt_BR };

export default keys;
