/* eslint-disable no-template-curly-in-string */
/**
 * @see https://github.com/jquense/yup/blob/master/src/locale.js
 */
import printValue from 'yup/lib/util/printValue';

const en_US = {
  mixed: {
    default: 'invalid',
    required: 'required',
    oneOf: 'must be one of the following values: ${values}',
    notOneOf: 'must not be one of the following values: ${values}',
    notType: ({ type, value, originalValue }) => {
      let isCast = originalValue != null && originalValue !== value;
      let msg =
        `must be a \`${type}\` type, ` +
        `but the final value was: \`${printValue(value, true)}\`` +
        (isCast ? ` (cast from the value \`${printValue(originalValue, true)}\`).` : '.');

      if (value === null) {
        msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``;
      }

      return msg;
    },
  },
  string: {
    length: 'must be exactly ${length} characters',
    min: 'must be at least ${min} characters',
    max: 'must be at most ${max} characters',
    matches: 'must match the following: "${regex}"',
    email: 'must be a valid email',
    url: 'must be a valid URL',
    trim: 'must be a trimmed string',
    lowercase: 'must be a lowercase string',
    uppercase: 'must be a upper case string',
    isvimeourl: 'must be a valid vimeo URL',
  },
  number: {
    min: 'must be greater than or equal to ${min}',
    max: 'must be less than or equal to ${max}',
    lessThan: 'must be less than ${less}',
    moreThan: 'must be greater than ${more}',
    notEqual: 'must be not equal to ${notEqual}',
    positive: 'must be a positive number',
    negative: 'must be a negative number',
    integer: 'must be an integer',
  },
  date: {
    min: 'must be later than ${min}',
    max: 'must be at earlier than ${max}',
  },
  boolean: {},
  object: {
    noUnknown: 'cannot have keys not specified in the object shape',
  },
  array: {
    min: 'must have at least ${min} items',
    max: 'must have less than or equal to ${max} items',
  },
};

export default en_US;
