/* eslint-disable no-template-curly-in-string */
/**
 * @see https://github.com/jquense/yup/blob/master/src/locale.js
 */
import printValue from 'yup/lib/util/printValue';

const pt_BR = {
  mixed: {
    default: 'inválido',
    required: 'obrigatório',
    oneOf: 'deve ser um dos seguintes valores: ${values}',
    notOneOf: 'não deve ser um dos seguintes valores: ${values}',
    notType: ({ type, value, originalValue }) => {
      let isCast = originalValue != null && originalValue !== value;
      let msg =
        `deve ser do tipo \`${type}\`, ` +
        `mas o valor final foi: \`${printValue(value, true)}\`` +
        (isCast ? ` (cast a partir do valor \`${printValue(originalValue, true)}\`).` : '.');

      if (value === null) {
        msg += `\n Se "null" for um valor vazio, marque o esquema como \`.nullable()\``;
      }

      return msg;
    },
  },
  string: {
    length: 'deve ter exatamente ${length} caracteres',
    min: 'deve ter no mínimo ${min} caracteres',
    max: 'deve ter no máximo ${max} caracteres',
    matches: 'deve corresponder ao seguinte: "${regex}"',
    email: 'deve ser um email válido',
    url: 'deve ser uma URL válido',
    trim: 'não deve ter espaços no começo e no fim',
    lowercase: 'deve ser em letras minúsculas',
    uppercase: 'deve ser em letras maiúsculas',
    isvimeourl: 'deve ser uma URL do vimeo válida',
  },
  number: {
    min: 'deve ser maior ou igual a ${min}',
    max: 'deve ser menor ou igual a ${max}',
    lessThan: 'deve ser menor que ${less}',
    moreThan: 'deve ser maior que ${more}',
    notEqual: 'não deve ser igual a ${notEqual}',
    positive: 'deve ser um número positivo',
    negative: 'deve ser um número negativo',
    integer: 'deve ser um número inteiro',
  },
  date: {
    min: 'deve ser após ${min}',
    max: 'deve ser antes de ${max}',
  },
  boolean: {},
  object: {
    noUnknown: 'não pode ter chaves não especificadas',
  },
  array: {
    min: 'deve ter no mínimo ${min} itens',
    max: 'deve ter no máximo ${max} itens',
  },
};

export default pt_BR;
