const namespace = 'ead.EadModulesPage';

const keys = {
  TITLE: `${namespace}.title`,
  CREATE_MODULE: `${namespace}.createModule`,
  EDIT_MODULE: `${namespace}.editModule`,
  DELETE_MODULE: `${namespace}.deleteModule`,
  CANCEL_BUTTON: `${namespace}.button.cancel`,
  DELETE_BUTTON: `${namespace}.button.delete`,
};

const en_US = {
  [keys.TITLE]: 'Modules',
  [keys.CREATE_MODULE]: 'Create module',
  [keys.EDIT_MODULE]: 'Edit module',
  [keys.DELETE_MODULE]: 'Delete module?',
  [keys.CANCEL_BUTTON]: 'Cancel',
  [keys.DELETE_BUTTON]: 'Delete',
};

const pt_BR = {
  [keys.TITLE]: 'Módulos',
  [keys.CREATE_MODULE]: 'Criar módulo',
  [keys.EDIT_MODULE]: 'Editar módulo',
  [keys.DELETE_MODULE]: 'Deletar módulo?',
  [keys.CANCEL_BUTTON]: 'Cancelar',
  [keys.DELETE_BUTTON]: 'Deletar',
};

export { en_US, pt_BR };

export default keys;
