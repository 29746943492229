import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useIntl } from 'react-intl';
import { TYPE as SELECT_TYPE } from 'baseui/select';
import PropTypes from 'prop-types';

import localeIds from './CompanySelect.locale';
import SelectVirtualized from './SelectVirtualized';

const COMPANY_OPTION = gql`
  fragment CompanyOption on Company {
    _id
    name
  }
`;

export const GET_COMPANIES = gql`
  query GetCompanies {
    companyMany(sort: NAME_ASC) {
      ...CompanyOption
    }
  }
  ${COMPANY_OPTION}
`;

const CompanySelect = ({ value, onChange, ...props }) => {
  const [getCompanies, { data, loading, called }] = useLazyQuery(GET_COMPANIES);
  const { formatMessage } = useIntl();

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const options = (data && data.companyMany) || [];
  return (
    <SelectVirtualized
      aria-label={formatMessage({ id: localeIds.PLACEHOLDER })}
      value={value}
      options={options}
      isLoading={called && loading}
      labelKey="name"
      valueKey="_id"
      onChange={onChange}
      maxDropdownHeight="400px"
      placeholder={formatMessage({ id: localeIds.PLACEHOLDER })}
      type={SELECT_TYPE.search}
      {...props}
    />
  );
};

CompanySelect.propTypes = {
  onChange: PropTypes.func,
};

CompanySelect.defaultProps = {
  onChange: () => {},
};

export default CompanySelect;
