import React, { useRef } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'baseui/button';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton } from 'baseui/modal';
import { getOr } from 'lodash/fp';
import { useIntl } from 'react-intl';

import localeIds from './EadModulesPage.locale';
import useEadResourcePage from './useEadResourcePage';
import { deleteCacheByKey } from '../apolloClient';
import PageLayout from '../app/PageLayout';
import EadModulesForm from './EadModulesForm';
import EadModulesTable from './EadModulesTable';
import { GET_MODULES_FIELD_OPTIONS } from './EadCoursesForm';

const DELETE_MODULE = gql`
  mutation DeleteModule($_id: MongoID!) {
    courseModuleRemoveById(_id: $_id) {
      _id: recordId
    }
  }
`;

const EadModulesPage = () => {
  const {
    isFormOpen,
    isConfirmDeleteOpen,
    selectedResource: selectedModule,
    openForm,
    closeForm,
    openConfirmDelete,
    closeConfirmDelete,
    openQuickPreview,
  } = useEadResourcePage();
  const [deleteModule, { loading: isDeleting }] = useMutation(DELETE_MODULE, {
    refetchQueries: ['GetModules', { query: GET_MODULES_FIELD_OPTIONS }],
    update: store => {
      deleteCacheByKey(store, 'courseModulePagination');
    },
  });
  const { formatMessage } = useIntl();
  const formRef = useRef(null);
  const isSubmitting = getOr(false, 'current.state.isSubmitting', formRef);
  const handleConfirmClick = async () => {
    try {
      await deleteModule({ variables: { _id: selectedModule._id } });
      closeConfirmDelete();
    } catch (error) {
      // TODO use toaster here
      console.error(error);
    }
  };
  return (
    <>
      <PageLayout
        title={formatMessage({ id: localeIds.TITLE })}
        actions={<Button onClick={() => openForm()}>{formatMessage({ id: localeIds.CREATE_MODULE })}</Button>}
      >
        <EadModulesTable onEdit={openForm} onDelete={openConfirmDelete} onQuickPreview={openQuickPreview} />
      </PageLayout>
      <Modal isOpen={isFormOpen} closeable={!isSubmitting} onClose={closeForm}>
        <ModalHeader>{formatMessage({ id: localeIds.CREATE_MODULE })}</ModalHeader>
        <ModalBody>
          <EadModulesForm ref={formRef} courseModule={selectedModule} onSuccess={closeForm} modal />
        </ModalBody>
      </Modal>
      <Modal isOpen={isConfirmDeleteOpen} closeable={!isDeleting} onClose={closeConfirmDelete}>
        <ModalHeader>{formatMessage({ id: localeIds.DELETE_MODULE })}</ModalHeader>
        <ModalFooter>
          <ModalButton disabled={isDeleting} onClick={closeConfirmDelete}>
            {formatMessage({ id: localeIds.CANCEL_BUTTON })}
          </ModalButton>
          <ModalButton disabled={isDeleting} isLoading={isDeleting} onClick={handleConfirmClick}>
            {formatMessage({ id: localeIds.DELETE_BUTTON })}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EadModulesPage;
