import React from 'react';
import { styled, useStyletron, ThemeProvider } from 'baseui';
import { Card, StyledBody } from 'baseui/card';

import themes from '../themes';
import AnthorLogo from '../common/AnthorLogo';
import FadeIn from '../common/FadeIn';
import LoginForm from './LoginForm';

const Centered = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: 600,
  background: $theme.colors.background,
}));

const LoginPage = () => {
  const [css, theme] = useStyletron();

  return (
    <ThemeProvider theme={themes.dark}>
      <Centered>
        <FadeIn slideDown>
          <Card style={{ width: 400 }}>
            <StyledBody>
              <div
                className={css({
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 0,
                  paddingLeft: theme.sizing.scale800,
                  paddingRight: theme.sizing.scale800,
                  paddingBottom: theme.sizing.scale800,
                  marginBottom: theme.sizing.scale800,
                  borderBottomColor: theme.borders.border400.borderColor,
                  borderBottomWidth: theme.borders.border400.borderWidth,
                  borderBottomStyle: theme.borders.border400.borderStyle,
                })}
              >
                <AnthorLogo style={{ width: 250 }} type="white" />
              </div>
              <LoginForm />
            </StyledBody>
          </Card>
        </FadeIn>
      </Centered>
    </ThemeProvider>
  );
};

export default LoginPage;
