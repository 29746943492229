import { KIND as TAG_KIND } from 'baseui/tag';

import { COMPANY } from '../constants';

const useConstants = () => {
  const parseCompanyStatus = companyStatus => {
    switch (companyStatus) {
      case COMPANY.STATUS.APPROVED:
        return {
          label: 'Ativo',
          tagKind: TAG_KIND.positive,
        };
      case COMPANY.STATUS.CANCELED:
        return {
          label: 'Cancelado',
          tagKind: TAG_KIND.negative,
        };
      case COMPANY.STATUS.PENDING:
        return {
          label: 'Pendente',
          tagKind: TAG_KIND.warning,
        };
      default:
        return {
          label: companyStatus,
          tagKind: TAG_KIND.neutral,
        };
    }
  };

  return {
    COMPANY,
    parseCompanyStatus,
  };
};

export default useConstants;
