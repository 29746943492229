import React from 'react';
import { styled } from 'baseui';
import { Heading, HeadingLevel } from 'baseui/heading';

const PageWrapper = styled('div', ({ $theme }) => ({
  paddingLeft: $theme.sizing.scale800,
  paddingRight: $theme.sizing.scale800,
  paddingBottom: $theme.sizing.scale800,
}));

const PageLayout = ({ children, title, actions }) => (
  <PageWrapper>
    <HeadingLevel>
      <Heading styleLevel={4} display="flex" justifyContent="space-between">
        {title}
        <div>{actions}</div>
      </Heading>
      {children}
    </HeadingLevel>
  </PageWrapper>
);

export default PageLayout;
