import React from 'react';
import { Button, KIND as BUTTON_KIND } from 'baseui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-regular-svg-icons';

import useTheme from './useTheme';

const ThemeButton = props => {
  const [theme, setTheme] = useTheme();

  return (
    <Button
      kind={BUTTON_KIND.minimal}
      overrides={{
        BaseButton: {
          style: {
            color: '#ffffff',
          },
        },
      }}
      onClick={() => {
        setTheme(theme === 'light' ? 'dark' : 'light');
      }}
      {...props}
    >
      {<FontAwesomeIcon style={{ width: '24px', height: '24px' }} icon={theme === 'light' ? faMoon : faSun} />}
    </Button>
  );
};

export default ThemeButton;
