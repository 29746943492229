const namespace = 'ead.EadQuestionsOptionForm';

const keys = {
  FIELD_ID: `${namespace}.field.id`,
  FIELD_DESCRIPTION: `${namespace}.field.description`,
  FIELD_CORRECT: `${namespace}.field.correct`,
  FIELD_REASON: `${namespace}.field.reason`,
  BUTTON_ADD_REASON: `${namespace}.button.addReason`,
};

const en_US = {
  [keys.FIELD_ID]: 'ID',
  [keys.FIELD_DESCRIPTION]: 'Description',
  [keys.FIELD_CORRECT]: 'Correct',
  [keys.FIELD_REASON]: 'Reason',
  [keys.BUTTON_ADD_REASON]: 'Add reason',
};

const pt_BR = {
  [keys.FIELD_ID]: 'ID',
  [keys.FIELD_DESCRIPTION]: 'Descrição',
  [keys.FIELD_CORRECT]: 'Correta',
  [keys.FIELD_REASON]: 'Razão',
  [keys.BUTTON_ADD_REASON]: 'Adicionar razão',
};

export { en_US, pt_BR };

export default keys;
