import React, { useRef } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'baseui/button';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton } from 'baseui/modal';
import { Label2, Paragraph2 } from 'baseui/typography';
import { useIntl } from 'react-intl';
import { getOr } from 'lodash/fp';

import useEadResourcePage from './useEadResourcePage';
import localeIds from './EadCoursesPage.locale';
import { deleteCacheByKey } from '../apolloClient';
import PageLayout from '../app/PageLayout';
import EadCoursesTable from './EadCoursesTable';
import EadCoursesForm from './EadCoursesForm';

const DELETE_COURSE = gql`
  mutation DeleteCourse($_id: MongoID!) {
    courseRemoveById(_id: $_id) {
      _id: recordId
    }
  }
`;

const EadCoursesPage = () => {
  const {
    isFormOpen,
    isConfirmDeleteOpen,
    isQuickPreviewOpen,
    selectedResource: selectedCourse,
    openForm,
    closeForm,
    openConfirmDelete,
    closeConfirmDelete,
    openQuickPreview,
    closeQuickPreview,
  } = useEadResourcePage();
  const [deleteCourse, { loading: isDeleting }] = useMutation(DELETE_COURSE, {
    refetchQueries: ['GetCourses'],
    update: store => {
      deleteCacheByKey(store, 'coursePagination');
    },
  });
  const { formatMessage } = useIntl();
  const formRef = useRef(null);
  const isSubmitting = getOr(false, 'current.state.isSubmitting', formRef);
  const handleConfirmClick = async () => {
    try {
      await deleteCourse({ variables: { _id: selectedCourse._id } });
      closeConfirmDelete();
    } catch (error) {
      // TODO add notification to modal
      console.error(error);
    }
  };

  return (
    <>
      <PageLayout
        title={formatMessage({ id: localeIds.TITLE })}
        actions={<Button onClick={() => openForm()}>{formatMessage({ id: localeIds.CREATE_COURSE })}</Button>}
      >
        <EadCoursesTable onEdit={openForm} onDelete={openConfirmDelete} onQuickPreview={openQuickPreview} />
      </PageLayout>
      <Modal isOpen={isFormOpen} closeable={!isSubmitting} onClose={closeForm}>
        <ModalHeader>
          {formatMessage({ id: selectedCourse ? localeIds.EDIT_COURSE : localeIds.CREATE_COURSE })}
        </ModalHeader>
        <ModalBody>
          <EadCoursesForm ref={formRef} course={selectedCourse} onSuccess={closeForm} modal />
        </ModalBody>
      </Modal>
      <Modal isOpen={isConfirmDeleteOpen} closeable={!isDeleting} onClose={closeConfirmDelete}>
        <ModalHeader>{formatMessage({ id: localeIds.DELETE_COURSE })}</ModalHeader>
        <ModalFooter>
          <ModalButton disabled={isDeleting} onClick={closeConfirmDelete}>
            {formatMessage({ id: localeIds.CANCEL_BUTTON })}
          </ModalButton>
          <ModalButton disabled={isDeleting} isLoading={isDeleting} onClick={handleConfirmClick}>
            {formatMessage({ id: localeIds.DELETE_BUTTON })}
          </ModalButton>
        </ModalFooter>
      </Modal>
      <Modal isOpen={isQuickPreviewOpen} onClose={closeQuickPreview}>
        {selectedCourse && (
          <>
            <ModalHeader>Curso</ModalHeader>
            <ModalBody>
              <Label2>{formatMessage({ id: localeIds.LABEL_ID })}</Label2>
              <Paragraph2>{selectedCourse._id}</Paragraph2>
              <Label2>{formatMessage({ id: localeIds.LABEL_NAME })}</Label2>
              <Paragraph2>{selectedCourse.name}</Paragraph2>
              <Label2>{formatMessage({ id: localeIds.LABEL_MIN_SCORE })}</Label2>
              <Paragraph2>{selectedCourse.minScore}%</Paragraph2>
            </ModalBody>
          </>
        )}
      </Modal>
    </>
  );
};

export default EadCoursesPage;
