import ApolloClient from 'apollo-boost';

import { DEFAULT_LOCALE } from './i18n';
import { DEFAULT_THEME } from './themes';
import { typeDefs, resolvers } from './resolvers';

export const THEME_STORAGE_KEY = 'anthor-theme';
export const LOCALE_STORAGE_KEY = 'anthor-locale';
export const USER_TOKEN_STORAGE_KEY = 'anthor-user-token';
export const USER_STORAGE_KEY = 'anthor-user';

const { REACT_APP_API_URL } = process.env;

if (REACT_APP_API_URL === undefined) {
  throw new Error('missing env REACT_APP_API_URL');
}

const client = new ApolloClient({
  uri: REACT_APP_API_URL,
  typeDefs,
  resolvers,
  request: operation => {
    const token = localStorage.getItem(USER_TOKEN_STORAGE_KEY);
    operation.setContext({
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    });
  },
});

const deleteCacheByKey = (store, key) => {
  Object.keys(store.data.data).forEach(cacheKey => {
    cacheKey.match(new RegExp(key)) && store.data.delete(cacheKey);
  });
};

const loadCache = () => {
  client.writeData({
    data: {
      user: JSON.parse(localStorage.getItem(USER_STORAGE_KEY)),
      theme: localStorage.getItem(THEME_STORAGE_KEY) || DEFAULT_THEME,
      locale: localStorage.getItem(LOCALE_STORAGE_KEY) || DEFAULT_LOCALE,
    },
  });
};

const clearCache = () => {
  localStorage.clear();
  client.writeData({
    data: {
      user: null,
      theme: DEFAULT_THEME,
      locale: DEFAULT_LOCALE,
    },
  });
};

loadCache();

client.onResetStore(clearCache);

export { deleteCacheByKey };

export default client;
