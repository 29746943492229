import React from 'react';
import { useStyletron } from 'baseui';
import { Navigation } from 'baseui/side-navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboard, faIndustry } from '@fortawesome/free-solid-svg-icons';
import { useIntl } from 'react-intl';

import localeIds from './Sidenav.locale';
import useRouter from './useRouter';

const Item = ({ icon, children }) => {
  const [css, theme] = useStyletron();
  const marginRight = css({ marginRight: theme.sizing.scale400 });

  return (
    <>
      {icon && <FontAwesomeIcon icon={icon} className={marginRight} />}
      {children}
    </>
  );
};

const Sidenav = () => {
  const { history, location } = useRouter();
  const { formatMessage } = useIntl();

  const items = [
    {
      title: <Item icon={faIndustry}>{formatMessage({ id: localeIds.INDUSTRY })}</Item>,
      itemId: '/industry',
    },
    {
      title: <Item icon={faChalkboard}>{formatMessage({ id: localeIds.EAD })}</Item>,
      subNav: [
        {
          title: <Item>{formatMessage({ id: localeIds.COURSES })}</Item>,
          itemId: '/ead/courses',
        },
        {
          title: <Item>{formatMessage({ id: localeIds.MODULES })}</Item>,
          itemId: '/ead/modules',
        },
        {
          title: <Item>{formatMessage({ id: localeIds.VIDEOS })}</Item>,
          itemId: '/ead/videos',
        },
        {
          title: <Item>{formatMessage({ id: localeIds.QUESTIONS })}</Item>,
          itemId: '/ead/questions',
        },
        {
          title: <Item>{formatMessage({ id: localeIds.PROMOTERS })}</Item>,
          itemId: '/ead/promoters',
        },
      ],
    },
  ];

  return (
    <Navigation
      items={items}
      activeItemId={location.pathname}
      onChange={({ event, item }) => {
        event.preventDefault();
        history.push(item.itemId);
      }}
    />
  );
};

export default Sidenav;
