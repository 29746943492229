import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { USER_STORAGE_KEY, USER_TOKEN_STORAGE_KEY } from '../apolloClient';

const GET_USER = gql`
  {
    user @client {
      id
      name
      email
    }
  }
`;

const useUser = () => {
  const { data, client } = useQuery(GET_USER);
  const user = data.user;
  const setUser = (user, token) => {
    client.writeData({ data: { user } });
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
    localStorage.setItem(USER_TOKEN_STORAGE_KEY, token);
  };

  return [user, setUser];
};

export default useUser;
