import React from 'react';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';

const FadeIn = ({ children, slideDown }) => {
  const props = useSpring({
    from: { opacity: 0, ...(slideDown && { transform: 'translateY(-100%)' }) },
    to: { opacity: 1, ...(slideDown && { transform: 'translateY(0)' }) },
  });

  return <animated.div style={props}>{children}</animated.div>;
};

FadeIn.propTypes = {
  slideDown: PropTypes.bool,
};

FadeIn.defaultProps = {
  slideDown: false,
};

export default FadeIn;
