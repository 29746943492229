import React, { useState } from 'react';
import { Drawer, ANCHOR } from 'baseui/drawer';

import PageLayout from '../app/PageLayout';
import IndustryTable from './IndustryTable';
import IndustryVoucherForm from './IndustryVoucherForm';

const IndustryPage = () => {
  const [isVoucherFormOpen, setIsVoucherFormOpen] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState({});

  const openVoucherForm = industry => {
    setIsVoucherFormOpen(true);
    setSelectedIndustry(industry);
  };
  const closeVoucherForm = () => {
    setIsVoucherFormOpen(false);
    setSelectedIndustry({});
  };

  return (
    <>
      <PageLayout title="Indústrias">
        <IndustryTable onVoucherClick={openVoucherForm} />
      </PageLayout>
      <Drawer
        isOpen={isVoucherFormOpen}
        anchor={ANCHOR.right}
        onClose={closeVoucherForm}
        overrides={{
          DrawerContainer: {
            style: {
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
            },
          },
        }}
      >
        <IndustryVoucherForm industry={selectedIndustry} onSuccess={closeVoucherForm} />
      </Drawer>
    </>
  );
};

export default IndustryPage;
