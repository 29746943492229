import en_US from './locale/en_US';
import pt_BR from './locale/pt_BR';
import baseUi_pt_BR from './locale/baseui_pt_BR';
import yup_en_US from './locale/yup_en_US';
import yup_pt_BR from './locale/yup_pt_BR';

const DEFAULT_LOCALE = 'pt-BR';

const locales = {
  'en-US': en_US,
  'pt-BR': pt_BR,
};

const baseUiLocales = {
  'en-US': {},
  'pt-BR': baseUi_pt_BR,
};

const yupLocales = {
  'en-US': yup_en_US,
  'pt-BR': yup_pt_BR,
};

export { DEFAULT_LOCALE, baseUiLocales, locales, yupLocales };
