const namespace = 'ead.EadQuestionsTable';

const keys = {
  COLUMN_ID: `${namespace}.column.id`,
  COLUMN_DESCRIPTION: `${namespace}.column.description`,
  COLUMN_MIN_SCORE: `${namespace}.column.minScore`,
  COLUMN_DIFFICULTY: `${namespace}.column.difficulty`,
  COLUMN_ACTIONS: `${namespace}.column.actions`,
};

const en_US = {
  [keys.COLUMN_ID]: 'ID',
  [keys.COLUMN_DESCRIPTION]: 'Description',
  [keys.COLUMN_MIN_SCORE]: 'Minimal Score',
  [keys.COLUMN_DIFFICULTY]: 'Difficulty',
  [keys.COLUMN_ACTIONS]: 'Actions',
};

const pt_BR = {
  [keys.COLUMN_ID]: 'ID',
  [keys.COLUMN_DESCRIPTION]: 'Descrição',
  [keys.COLUMN_MIN_SCORE]: 'Pontuação Mínima',
  [keys.COLUMN_DIFFICULTY]: 'Dificuldade',
  [keys.COLUMN_ACTIONS]: 'Ações',
};

export { en_US, pt_BR };

export default keys;
