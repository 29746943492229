import { useState } from 'react';

const useEadResourcePage = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isQuickPreviewOpen, setIsQuickPreviewOpen] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);

  const open = setOpenCb => (resource = null) => {
    setOpenCb(true);
    setSelectedResource(resource);
  };
  const close = setCloseCb => () => {
    setCloseCb(false);
    setSelectedResource(null);
  };
  const openForm = open(setIsFormOpen);
  const closeForm = close(setIsFormOpen);
  const openConfirmDelete = open(setIsConfirmDeleteOpen);
  const closeConfirmDelete = close(setIsConfirmDeleteOpen);
  const openQuickPreview = open(setIsQuickPreviewOpen);
  const closeQuickPreview = close(setIsQuickPreviewOpen);

  return {
    isFormOpen,
    isConfirmDeleteOpen,
    isQuickPreviewOpen,
    selectedResource,
    openForm,
    closeForm,
    openConfirmDelete,
    closeConfirmDelete,
    openQuickPreview,
    closeQuickPreview,
  };
};

export default useEadResourcePage;
