import React, { forwardRef } from 'react';
import { gql } from 'apollo-boost';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

import localeIds from './EadVideosForm.locale';
import getVimeoData, { getVimeoIdByUrl, getVimeoUrlById } from '../utils/getVimeoData';
import { deleteCacheByKey } from '../apolloClient';
import InputField from '../common/InputField';
import SelectField from '../common/SelectField';
import FormFooterButtons from '../common/FormFooterButtons';
import { GET_VIDEOS_FIELD_OPTIONS } from './EadModulesForm';

const QUESTION_OPTION = gql`
  fragment QuestionOption on CourseQuestion {
    _id
    description
  }
`;

const VIDEO_FIELDS = gql`
  fragment VideoFields on CourseVideo {
    _id
    title
    url
    videoUri
    minNumQuestions
    questions {
      ...QuestionOption
    }
  }
  ${QUESTION_OPTION}
`;

const CREATE_VIDEO = gql`
  mutation CreateVideo($record: CreateOneCourseVideoInput!) {
    video: createCourseVideo(record: $record) {
      record {
        ...VideoFields
      }
    }
  }
  ${VIDEO_FIELDS}
`;

const UPDATE_VIDEO = gql`
  mutation UpdateVideo($record: UpdateByIdCourseVideoInput!) {
    video: updateCourseVideo(record: $record) {
      record {
        ...VideoFields
      }
    }
  }
  ${VIDEO_FIELDS}
`;

export const GET_QUESTIONS_FIELD_OPTIONS = gql`
  query GetQuestionsFieldOptions {
    courseQuestionMany {
      ...QuestionOption
    }
  }
  ${QUESTION_OPTION}
`;

const EadVideosForm = forwardRef(({ video, onSuccess, modal }, ref) => {
  const [createVideo] = useMutation(CREATE_VIDEO, {
    refetchQueries: ['GetVideos', { query: GET_VIDEOS_FIELD_OPTIONS }],
    update: store => {
      deleteCacheByKey(store, 'courseVideoPagination');
    },
  });
  const [updateVideo] = useMutation(UPDATE_VIDEO);
  const { formatMessage } = useIntl();
  const { data: questionsOptionsData, loading: loadingQuestionsOptions } = useQuery(GET_QUESTIONS_FIELD_OPTIONS);
  const isEditing = Boolean(video);
  const questionsOptions = questionsOptionsData ? questionsOptionsData.courseQuestionMany : [];
  const initialValues = {
    url: '',
    minNumQuestions: '',
    questions: [],
    ...(isEditing && {
      _id: video._id,
      videoUri: video.videoUri || '',
      url: getVimeoUrlById(video.url) || '',
      minNumQuestions: typeof video.minNumQuestions === 'number' ? video.minNumQuestions : '',
      questions: video.questions || [],
    }),
  };
  const validationSchema = Yup.object().shape({
    url: Yup.string()
      .required()
      .vimeoUrl(),
    videoUri: Yup.string().required(),
    minNumQuestions: Yup.number()
      .min(0)
      .required(),
    questions: Yup.array(),
  });
  const handleSubmit = async ({ url, videoUri, minNumQuestions, questions }, { setSubmitting }) => {
    try {
      const vimeoData = await getVimeoData(url);
      const variables = {
        record: {
          url: getVimeoIdByUrl(url),
          videoUri,
          minNumQuestions: Number(minNumQuestions),
          questions: questions.map(({ _id }) => _id),
          title: vimeoData.title,
          length: vimeoData.duration,
          ...(isEditing && { _id: video._id }),
        },
      };
      const request = isEditing ? updateVideo : createVideo;
      const { data } = await request({ variables });
      onSuccess(data.video.record);
    } catch (error) {
      // TODO implement feedback error
      console.error(error);
    }
    setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} ref={ref}>
      {({ handleSubmit, handleReset, isSubmitting }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          {isEditing && (
            <Field name="_id" label={formatMessage({ id: localeIds.FIELD_ID })} component={InputField} disabled />
          )}
          <Field
            name="url"
            label={formatMessage({ id: localeIds.FIELD_URL })}
            component={InputField}
            type="text"
            autoComplete="off"
          />
          <Field
            name="videoUri"
            label={formatMessage({ id: localeIds.FIELD_URI })}
            component={InputField}
            type="text"
            autoComplete="off"
          />
          <Field
            name="minNumQuestions"
            label={formatMessage({ id: localeIds.FIELD_MIN_NUM_QUESTIONS })}
            component={InputField}
            type="number"
            steps="1"
            autoComplete="off"
            overrides={{ Input: { props: { min: 0 } } }}
          />
          <Field
            label={formatMessage({ id: localeIds.FIELD_QUESTIONS })}
            name="questions"
            component={SelectField}
            isLoading={loadingQuestionsOptions}
            options={questionsOptions}
            labelKey="description"
            valueKey="_id"
            multi
            autoComplete="off"
          />
          <FormFooterButtons
            onClear={handleReset}
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            clearable={!isEditing}
            modal={modal}
          />
        </form>
      )}
    </Formik>
  );
});

export default EadVideosForm;
