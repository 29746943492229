const namespace = 'components.CompanySelect';

const keys = {
  PLACEHOLDER: `${namespace}.placeholder`,
};

const en_US = {
  [keys.PLACEHOLDER]: 'Pick a company',
};

const pt_BR = {
  [keys.PLACEHOLDER]: 'Selecione uma empresa',
};

export { en_US, pt_BR };

export default keys;
