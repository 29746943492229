/**
 * Custom yup validators
 */

import * as Yup from 'yup';

import getVimeoData from './utils/getVimeoData';

// cache validated urls
const validatedUrls = [];

Yup.addMethod(Yup.string, 'vimeoUrl', function() {
  return this.test('is-vimeo-url', 'invalid vimeo url', async value => {
    if (!value) {
      return false;
    }

    if (validatedUrls.includes(value)) {
      return true;
    }

    try {
      await getVimeoData(value);
      validatedUrls.push(value);
      return true;
    } catch (error) {
      return false;
    }
  });
});
