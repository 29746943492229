import React from 'react';
import { styled } from 'baseui';
import { Button, KIND } from 'baseui/button';
import { ModalFooter, ModalButton } from 'baseui/modal';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import localeIds from './FormFooterButtons.locale';

const Wrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
});

const FormFooterButtons = ({ onClear, isDisabled, isLoading, clearable, modal }) => {
  const { formatMessage } = useIntl();
  const WrapperComponent = modal ? ModalFooter : Wrapper;
  const ButtonComponent = modal ? ModalButton : Button;
  return (
    <WrapperComponent style={{ marginLeft: 0, marginRight: 0 }}>
      {clearable && (
        <ButtonComponent
          type="button"
          onClick={onClear}
          disabled={isDisabled}
          kind={KIND.minimal}
          style={{ marginLeft: 12 }}
        >
          {formatMessage({ id: localeIds.BUTTON_CLEAR })}
        </ButtonComponent>
      )}
      <ButtonComponent
        type="submit"
        disabled={isDisabled}
        isLoading={isLoading}
        kind={KIND.primary}
        style={{ marginLeft: 12 }}
      >
        {formatMessage({ id: localeIds.BUTTON_SAVE })}
      </ButtonComponent>
    </WrapperComponent>
  );
};

FormFooterButtons.propTypes = {
  onClear: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  clearable: PropTypes.bool,
  modal: PropTypes.bool,
};

FormFooterButtons.defaultProps = {
  onClear: () => {},
  isDisabled: false,
  isLoading: false,
  clearable: false,
  modal: false,
};

export default FormFooterButtons;
