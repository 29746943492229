import React, { useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Button, SIZE as BUTTON_SIZE, KIND as BUTTON_KIND } from 'baseui/button';
import { SIZE as INPUT_SIZE } from 'baseui/input';
import { Formik, Field } from 'formik';
import { Notification, KIND as NOTIFICATION_KIND } from 'baseui/notification';
import * as Yup from 'yup';
import jwtDecode from 'jwt-decode';
import { getOr } from 'lodash/fp';
import md5 from 'crypto-js/md5';

import useRouter from './useRouter';
import useUser from './useUser';
import InputField from '../common/InputField';

const AUTH_USER = gql`
  query AuthUser($email: String, $password: String, $type: String) {
    authUser(email: $email, password: $password, type: $type)
  }
`;

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  password: Yup.string()
    .min(1)
    .max(20)
    .required(),
});

const LoginForm = () => {
  const [error, setError] = useState('');
  const [, setUser] = useUser();
  const { history, location } = useRouter();
  const client = useApolloClient();
  const redirectTo = getOr({ pathname: '/' }, 'state.from', location);

  return (
    <>
      {error && (
        <Notification
          closeable
          kind={NOTIFICATION_KIND.negative}
          onClose={() => setError('')}
          overrides={{
            Body: { style: { width: 'auto' } },
          }}
        >
          {error}
        </Notification>
      )}
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={LoginSchema}
        onSubmit={async ({ email, password }, { setSubmitting }) => {
          try {
            const result = await client.query({
              query: AUTH_USER,
              variables: {
                email,
                password: md5(password).toString(),
                type: 'admin',
              },
            });
            const jwt = result.data.authUser;
            const user = jwtDecode(jwt);
            setUser(
              {
                id: user.id,
                email: user.email,
                name: user.name,
                __typename: 'AppUser',
              },
              jwt
            );
            history.replace(redirectTo);
          } catch (error) {
            console.error(error);
            setError(error.message.replace('GraphQL error: ', ''));
          }
          setSubmitting(false);
        }}
        render={({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Field component={InputField} name="email" label="Email" type="text" size={INPUT_SIZE.default} />
            <Field component={InputField} name="password" label="Password" type="password" size={INPUT_SIZE.default} />
            <Button
              type="submit"
              isLoading={isSubmitting}
              style={{ width: '100%' }}
              size={BUTTON_SIZE.default}
              kind={BUTTON_KIND.primary}
            >
              Login
            </Button>
          </form>
        )}
      />
    </>
  );
};

export default LoginForm;
