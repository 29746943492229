const namespace = 'components.UserButton';

const keys = {
  LOGOUT: `${namespace}.logout`,
};

const en_US = {
  [keys.LOGOUT]: 'Logout',
};

const pt_BR = {
  [keys.LOGOUT]: 'Sair',
};

export { en_US, pt_BR };

export default keys;
